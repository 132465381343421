import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

const selectTransferUpdate = (state: AppState) => state.transferUpdate;

interface Props {
  onRefresh: () => void;
}

export const useTransferUpdate = ({ onRefresh }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedPaymentId } = useAppSelector(selectTransferUpdate);

  useEffect(() => {
    if (justUpdatedPaymentId) {
      toast({ description: "Mise à jour effectuée avec succès !" });
      onRefresh();
    }
  }, [justUpdatedPaymentId, dispatch, onRefresh, toast]);
};
