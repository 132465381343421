import { createReducer } from "@reduxjs/toolkit";
import { adminCreateInternalTraining, adminResetCreateInternalTraining } from "./create-internal-training";
import { CreationState } from "@redux/app-state";

const initialState: CreationState = { justCreatedId: null, processing: "idle" };

export const adminCreateInternalTrainingReducer = createReducer<CreationState>(initialState, builder => {
  builder.addCase(adminCreateInternalTraining.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedId: payload,
      processing: "success",
    };
  });
  builder.addCase(adminCreateInternalTraining.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(adminCreateInternalTraining.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(adminResetCreateInternalTraining, () => {
    return initialState;
  });
});
