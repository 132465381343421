import { createReducer } from "@reduxjs/toolkit";
import { teacherRetrieveTrainingFollowUp } from "./retrieve-training-follow-up";
import { EducationalAdvisorTrainingFollowUpVM } from "@academy-context/read/domain/types/training-follow-up";
import { RetrievalState } from "@redux/app-state";
import { Nullable } from "@shared-kernel/core/types/nullable";

const initialState: RetrievalState<Nullable<EducationalAdvisorTrainingFollowUpVM>> = { fetching: "idle", data: null };

export const teacherRetrieveTrainingFollowUpReducer = createReducer<RetrievalState<Nullable<EducationalAdvisorTrainingFollowUpVM>>>(
  initialState,
  builder => {
    builder.addCase(teacherRetrieveTrainingFollowUp.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(teacherRetrieveTrainingFollowUp.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(teacherRetrieveTrainingFollowUp.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
  }
);
