import { createReducer } from "@reduxjs/toolkit";
import { adminRetrieveTrainingFollowUpObjectives } from "./retrieve-training-follow-up-objectives";
import { AdminTrainingFollowUpObjectivesRetrievalState } from "@redux/app-state";

const initialState: AdminTrainingFollowUpObjectivesRetrievalState = {
  fetching: "idle",
  data: { studentObjectives: [], teacherObjectives: [] },
};

export const adminRetrieveTrainingFollowUpObjectivesReducer = createReducer<AdminTrainingFollowUpObjectivesRetrievalState>(
  initialState,
  builder => {
    builder.addCase(adminRetrieveTrainingFollowUpObjectives.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(adminRetrieveTrainingFollowUpObjectives.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(adminRetrieveTrainingFollowUpObjectives.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
  }
);
