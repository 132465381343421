import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveInternalTrainings } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/retrieve-internal-trainings";
import { adminResetDeleteInternalTraining } from "@academy-context/write/application/use-cases/admin/internal-training-deletion/delete-internal-training";

export const selectInternalTrainingDeleted = (state: AppState) => state.adminInternalTrainingDeletion;

export const useInternalTrainingDeleted = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectInternalTrainingDeleted);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Formation supprimée !" });
      dispatch(adminResetDeleteInternalTraining());
      dispatch(adminRetrieveInternalTrainings());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la suppression de la formation", variant: "destructive" });
    }
  }, [dispatch, processing, toast]);
};
