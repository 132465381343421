import { StudentCreate } from "@academy-context/primary/admin/student-detail/student-create.components";
import { StudentsList } from "@academy-context/primary/admin/student-list/student-list.components";
import { TeacherCreate } from "@academy-context/primary/admin/teacher-detail/teacher-create.components";
import { TeacherAdminDetail } from "@academy-context/primary/admin/teacher-detail/teacher-admin-detail.components";
import { TeachersList } from "@academy-context/primary/admin/teacher-list/teacher-list.components";
import { BudgetAdminDetail } from "@academy-context/primary/admin/budget-detail/budget-admin-detail.components";
import { BudgetsList } from "@academy-context/primary/admin/budget-list/budget-list.components";
import { UserDetail } from "@user-management-context/primary/user/user-detail/user-detail.components";
import { LessonAdminDetail } from "@academy-context/primary/admin/lesson-admin-detail/lesson-admin-detail.components";
import { LessonsList } from "@academy-context/primary/admin/lesson-list/lesson-list.components";
import { TransfersList } from "@academy-context/primary/admin/transfers-list/transfers-list.components";
import { LessonTeacherDetail } from "@academy-context/primary/teacher/lesson-teacher-detail/lesson-teacher-detail.components";
import { InvitationsPage } from "./pages/admin/user-invitations.page";
import { TransfersSummary } from "@academy-context/primary/admin/transfers-summary/transfers-summary.components";
import { TeacherUserDetail } from "@user-management-context/primary/teacher/teacher-user-detail/teacher-user-detail.components";
import { TeacherLessonsList } from "@academy-context/primary/teacher/lesson-list/lesson-list.components";
import { StudentUserDetail } from "@user-management-context/primary/student/student-user-detail/student-user-detail.components";
import { StudentLessonList } from "@academy-context/primary/student/lesson-list/lesson-list.components";
import { ExpenseAdminDetail } from "@academy-context/primary/admin/expense-detail/expense-admin-detail.components";
import { ExpensesList } from "@academy-context/primary/admin/expense-list/expense-list.components";
import { StudentExpenseList } from "@academy-context/primary/student/expense-list/expense-list.components";
import { ConventionAdminDetail } from "@academy-context/primary/admin/convention-detail/convention-admin-detail.components";
import { ConventionsList } from "@academy-context/primary/admin/convention-list/convention-list.components";
import { ConventionTransfersList } from "@academy-context/primary/admin/convention-transfers-list/convention-transfers-list.components";
import { TeacherConventionList } from "@academy-context/primary/teacher/convention-list/convention-list.components";
import { StudentConventionList } from "@academy-context/primary/student/convention-list/convention-list.components";
import { StudentBudgetsList } from "@academy-context/primary/student/budget-list/budget-list.components";
import { StudentSummaryContainer } from "@academy-context/primary/student/student-summary/student-summary-container.components";
import { FundingRequestHomeScreen } from "@academy-context/primary/student/funding-request/home-screen.components";
import { FundingRequestCreate } from "@academy-context/primary/student/funding-request/funding-request-create.components";
import { FundingRequestSubmissionSuccess } from "@academy-context/primary/student/funding-request/submission-success-screen.components";
import { FundingRequestToSubmitList } from "@academy-context/primary/admin/funding-request-list/funding-request-to-submit-list.components";
import { FundingRequestDetail } from "@academy-context/primary/admin/funding-request-detail/funding-request-detail.components";
import { StudentAttendanceSheetForm } from "@academy-context/primary/shared/attendance-sheet-signature-form/student-attendance-sheet-form";
import { StudentTrainingFollowUpDetail } from "@academy-context/primary/student/training-follow-up-detail/training-follow-up-detail.components";
import { TrainingFollowUpSurvey } from "@academy-context/primary/student/training-follow-up-detail/training-follow-up-survey.components";
import { StudentTrainingFollowUpList } from "@academy-context/primary/student/training-follow-up-list/training-follow-up-list.components";
import { StudentTrainingFollowUpObjectives } from "@academy-context/primary/student/training-follow-up-detail/student-training-follow-up-objectives";
import { TrainingFollowUpList } from "@academy-context/primary/admin/training-follow-up-list/training-follow-up-list.components";
import { AdminTrainingFollowUpDetail } from "@academy-context/primary/admin/training-follow-up-detail/training-follow-up-detail.components";
import { AdminTrainingFollowUpObjectives } from "@academy-context/primary/admin/training-follow-up-detail/admin-training-follow-up-objectives";
import { OldConventionsList } from "@academy-context/primary/admin/convention-list/old-convention-list.components";
import { RefundRequestDetail } from "@academy-context/primary/student/refund-request-detail/refund-request-detail.components";
import { RefundRequestSuccess } from "@academy-context/primary/student/refund-request-detail/refund-request-success.components";
import { CollaborationRequests } from "@academy-context/primary/admin/collaboration-request-list/collaboration-request-list";
import { AdminProviderTrainingsPage } from "@pages/admin/provider-trainings.page";
import { ProviderListPage } from "@pages/admin/provider-list.page";
import { ProviderCreatePage } from "@pages/admin/provider-create.page";
import { ProviderDetailsPage } from "@pages/admin/provider-details.page";
import { AdminProviderBatchListPage } from "@pages/admin/provider-batch-list.page";
import { ProviderProfilePage } from "@pages/provider/provider-profile.page";
import { ProviderTrainingsPage } from "@pages/provider/provider-trainings.page";
import { ProviderBatchListPage } from "@pages/provider/provider-batch-list.page";
import { ProviderTrainingFollowUpListPage } from "@pages/provider/training-follow-up-list.page";
import { TeacherTrainingFollowUpListPage } from "@pages/teacher/training-follow-up-list.page";
import { ProviderTrainingFollowUpDetailPage } from "@pages/provider/training-follow-up-detail.page";
import { TeacherTrainingFollowUpDetailPage } from "@pages/teacher/training-follow-up-detail.page";
import { TeacherTrainingFollowUpObjectivesPage } from "@pages/teacher/training-follow-up-objectives.page";
import { ProviderTrainingFollowUpObjectivesPage } from "@pages/provider/training-follow-up-objectives.page";
import { TeacherTrainingFollowUpAttendanceSheetListPage } from "@pages/teacher/training-follow-up-attendance-sheet-list.page";
import { AdminTrainingFollowUpAttendanceSheetListPage } from "@pages/admin/training-follow-up-attendance-sheet-list.page";
import { ProviderTrainingFollowUpAttendanceSheetListPage } from "@pages/provider/training-follow-up-attendance-sheet-list.page";
import { FundingRequestToReviewList } from "@academy-context/primary/admin/funding-request-list/funding-request-to-review-list.components";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { StudentDetailsPage } from "@pages/admin/student-details.page";
import { AdminInternalTrainingsPage } from "@pages/admin/internal-trainings.page";

export interface AppRoute {
  path: string;
  element: JSX.Element;
}
export const TRAINING_FOLLOW_UP_ROUTE_PATH = "training-follow-ups";
export const REFUND_REQUEST_ROUTE_PATH = "refund-requests";

export const ROLE_BASED_URLS = {
  [ROLES.STUDENT]: {
    dashboard: "/dashboard",
    fundingRequest: {
      base: "/funding-request",
      new: "/funding-request/new",
      success: "/funding-request/success",
    },
    trainingFollowUp: {
      list: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}`,
      details: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id`,
      sessions: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/sessions`,
      objectives: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/objectives`,
      preTrainingSurvey: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/pre-training-survey`,
      postTrainingSurvey: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/post-training-survey`,
    },
    refundRequest: {
      base: `/${REFUND_REQUEST_ROUTE_PATH}`,
      success: `/${REFUND_REQUEST_ROUTE_PATH}/success`,
    },
    lesson: { list: "/lessons" },
    expense: { list: "/expenses" },
    budget: { list: "/budgets" },
    convention: { list: "/conventions/list" },
    profile: "/profile",
  },
  [ROLES.TEACHER]: {
    dashboard: "/dashboard",
    trainingFollowUp: {
      list: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}`,
      details: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id`,
      sessions: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/sessions`,
      objectives: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/objectives`,
    },
    lesson: { list: "/lessons/list" },
    convention: { list: "/conventions/list" },
    profile: "/profile",
  },
  [ROLES.PROVIDER]: {
    dashboard: "/dashboard",
    trainingFollowUp: {
      list: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}`,
      details: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id`,
      sessions: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/sessions`,
      objectives: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/objectives`,
    },
    providerTraining: { list: "/provider-trainings" },
    profile: "/profile",
  },
  [ROLES.ADMIN]: {
    lesson: {
      base: "/lessons",
      list: "/lessons/list",
    },
    budget: {
      base: "/budgets",
      list: "/budgets/list",
    },
    expense: {
      base: "/expenses",
      list: "/expenses/list",
    },
    convention: {
      base: "/conventions",
      list: "/conventions/list",
      oldList: "/old-conventions/list",
    },
    collaborationRequest: { list: "/collaboration-requests" },
    invitation: {
      list: "/invitations",
    },
    provider: {
      list: "/providers",
      create: "/providers/create",
      detail: "/providers/:id",
      trainings: "/provider-trainings",
      batches: "/provider-batches",
    },
    internalTraining: {
      list: "/internal-trainings",
    },
    student: {
      list: "/students",
      create: "/students/create",
      detail: "/students/:id",
    },
    teacher: {
      list: "/teachers",
      create: "/teachers/create",
      detail: "/teachers/:id",
    },
    payment: {
      list: "/payments",
    },
    transfer: {
      list: "/transfers/list",
      convention: "/convention-transfers",
    },
    fundingRequest: {
      toSubmit: "/to-submit-funding-requests",
      list: "/funding-requests",
      detail: "/funding-requests/:id",
    },
    trainingFollowUp: {
      list: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}`,
      details: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id`,
      studentAttendanceSheet: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/sessions/student`,
      teacherAttendanceSheet: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/sessions/teacher`,
      objectives: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/objectives`,
      preTrainingSurvey: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/pre-training-survey`,
      postTrainingSurvey: `/${TRAINING_FOLLOW_UP_ROUTE_PATH}/:id/post-training-survey`,
    },
    profile: "/profile",
  },
};

const adminRoutes: AppRoute[] = [
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["lesson"].base,
    element: <LessonAdminDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["lesson"].list,
    element: <LessonsList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["budget"].base,
    element: <BudgetAdminDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["budget"].list,
    element: <BudgetsList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["expense"].base,
    element: <ExpenseAdminDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["expense"].list,
    element: <ExpensesList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["convention"].base,
    element: <ConventionAdminDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["convention"].list,
    element: <ConventionsList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["convention"].oldList,
    element: <OldConventionsList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["collaborationRequest"].list,
    element: <CollaborationRequests />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["invitation"].list,
    element: <InvitationsPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["provider"].list,
    element: <ProviderListPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["provider"].create,
    element: <ProviderCreatePage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["provider"].detail,
    element: <ProviderDetailsPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["provider"].trainings,
    element: <AdminProviderTrainingsPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["provider"].batches,
    element: <AdminProviderBatchListPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["internalTraining"].list,
    element: <AdminInternalTrainingsPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["student"].list,
    element: <StudentsList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["student"].create,
    element: <StudentCreate />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["student"].detail,
    element: <StudentDetailsPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["teacher"].list,
    element: <TeachersList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["teacher"].create,
    element: <TeacherCreate />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["teacher"].detail,
    element: <TeacherAdminDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["payment"].list,
    element: <TransfersSummary />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["transfer"].list,
    element: <TransfersList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["transfer"].convention,
    element: <ConventionTransfersList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["fundingRequest"].detail,
    element: <FundingRequestDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["fundingRequest"].toSubmit,
    element: <FundingRequestToSubmitList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["fundingRequest"].list,
    element: <FundingRequestToReviewList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["trainingFollowUp"].details,
    element: <AdminTrainingFollowUpDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["trainingFollowUp"].list,
    element: <TrainingFollowUpList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["trainingFollowUp"].studentAttendanceSheet,
    element: <StudentAttendanceSheetForm isAdmin />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["trainingFollowUp"].teacherAttendanceSheet,
    element: <AdminTrainingFollowUpAttendanceSheetListPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["trainingFollowUp"].preTrainingSurvey,
    element: <TrainingFollowUpSurvey />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["trainingFollowUp"].postTrainingSurvey,
    element: <TrainingFollowUpSurvey />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["trainingFollowUp"].objectives,
    element: <AdminTrainingFollowUpObjectives />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.ADMIN]["profile"],
    element: <UserDetail />,
  },
];

const studentRoutes: AppRoute[] = [
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["dashboard"],
    element: <StudentSummaryContainer />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["fundingRequest"].base,
    element: <FundingRequestHomeScreen />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["fundingRequest"].new,
    element: <FundingRequestCreate />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["fundingRequest"].success,
    element: <FundingRequestSubmissionSuccess />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["trainingFollowUp"].list,
    element: <StudentTrainingFollowUpList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["trainingFollowUp"].details,
    element: <StudentTrainingFollowUpDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["trainingFollowUp"].sessions,
    element: <StudentAttendanceSheetForm />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["trainingFollowUp"].objectives,
    element: <StudentTrainingFollowUpObjectives />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["trainingFollowUp"].preTrainingSurvey,
    element: <TrainingFollowUpSurvey />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["trainingFollowUp"].postTrainingSurvey,
    element: <TrainingFollowUpSurvey />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["refundRequest"].base,
    element: <RefundRequestDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["refundRequest"].success,
    element: <RefundRequestSuccess />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["lesson"].list,
    element: <StudentLessonList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["expense"].list,
    element: <StudentExpenseList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["budget"].list,
    element: <StudentBudgetsList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["convention"].list,
    element: <StudentConventionList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.STUDENT]["profile"],
    element: <StudentUserDetail />,
  },
];

const teacherRoutes: AppRoute[] = [
  {
    path: ROLE_BASED_URLS[ROLES.TEACHER]["dashboard"],
    element: <LessonTeacherDetail />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.TEACHER]["trainingFollowUp"].list,
    element: <TeacherTrainingFollowUpListPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.TEACHER]["trainingFollowUp"].details,
    element: <TeacherTrainingFollowUpDetailPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.TEACHER]["trainingFollowUp"].sessions,
    element: <TeacherTrainingFollowUpAttendanceSheetListPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.TEACHER]["trainingFollowUp"].objectives,
    element: <TeacherTrainingFollowUpObjectivesPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.TEACHER]["lesson"].list,
    element: <TeacherLessonsList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.TEACHER]["convention"].list,
    element: <TeacherConventionList />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.TEACHER]["profile"],
    element: <TeacherUserDetail />,
  },
];

const providerRoutes: AppRoute[] = [
  {
    path: ROLE_BASED_URLS[ROLES.PROVIDER]["dashboard"],
    element: <ProviderBatchListPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.PROVIDER]["providerTraining"].list,
    element: <ProviderTrainingsPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.PROVIDER]["trainingFollowUp"].list,
    element: <ProviderTrainingFollowUpListPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.PROVIDER]["trainingFollowUp"].details,
    element: <ProviderTrainingFollowUpDetailPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.PROVIDER]["trainingFollowUp"].sessions,
    element: <ProviderTrainingFollowUpAttendanceSheetListPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.PROVIDER]["trainingFollowUp"].objectives,
    element: <ProviderTrainingFollowUpObjectivesPage />,
  },
  {
    path: ROLE_BASED_URLS[ROLES.PROVIDER]["profile"],
    element: <ProviderProfilePage />,
  },
];

export { studentRoutes, adminRoutes, teacherRoutes, providerRoutes };
