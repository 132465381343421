import { createReducer } from "@reduxjs/toolkit";
import { resetTeacherRetrieveTrainingFollowUps, teacherRetrieveTrainingFollowUps } from "./retrieve-training-follow-ups";
import { RetrievalState } from "@redux/app-state";
import { TrainingFollowUpListVM } from "@academy-context/read/domain/types/training-follow-up";

const initialState: RetrievalState<TrainingFollowUpListVM[]> = { fetching: "idle", data: [] };

export const teacherRetrieveTrainingFollowUpsReducer = createReducer<RetrievalState<TrainingFollowUpListVM[]>>(initialState, builder => {
  builder.addCase(teacherRetrieveTrainingFollowUps.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(teacherRetrieveTrainingFollowUps.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(teacherRetrieveTrainingFollowUps.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetTeacherRetrieveTrainingFollowUps, () => {
    return initialState;
  });
});
