import { isLoadingState, sortAlphabetically } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";

export const adminSelectInternalTrainingsRetrievalForForm = createSelector(
  [(state: AppState) => state.adminInternalTrainingsRetrieval],
  internalTrainingsRetrieval => {
    const { data, fetching } = internalTrainingsRetrieval;

    const formattedData = [...data]
      .sort((a, b) => sortAlphabetically(a.title, b.title))
      .map(training => ({ label: training.title, value: training.id }));

    return {
      trainings: formattedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
