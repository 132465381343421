import { Skeleton } from "@shared-kernel/primary/shared/shadcn/ui/skeleton";

export const InternalTrainingListSkeleton = () => {
  return (
    <div className="grid grid-cols-6">
      <div className="col-span-2 flex flex-col items-center space-y-2 p-2">
        <Skeleton className="h-[40px] w-full" />
        <Skeleton className="h-[500px] w-full" />
      </div>
      <div className="col-span-4 py-2">
        <Skeleton className="h-full" />
      </div>
    </div>
  );
};
