import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingFollowUpObjective } from "../../../../domain/types/training-follow-up";

export const adminRetrieveTrainingFollowUpObjectives = createAsyncThunk<
  { studentObjectives: TrainingFollowUpObjective[]; teacherObjectives: TrainingFollowUpObjective[] },
  { trainingId: string },
  { extra: Partial<Dependencies> }
>(
  "trainingObjectives/adminRetrieveTrainingFollowUpObjectives",
  async ({ trainingId }: { trainingId: string }, { extra: { trainingFollowUpGateway } }) => {
    const studentObjectives = await trainingFollowUpGateway!.getTrainingFollowUpObjectives(trainingId, "student");
    const teacherObjectives = await trainingFollowUpGateway!.getTrainingFollowUpObjectives(trainingId, "teacher");
    return { studentObjectives, teacherObjectives };
  }
);
