import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import { Check } from "lucide-react";

interface Props {
  title: string;
  date: string;
}

export const TrainingFollowUpDetailsHeader = ({ title, date }: Props) => (
  <div className="mt-5">
    <span>
      <strong>{new Date(date).toLocaleDateString("fr-FR")}</strong>
    </span>
    <span className="ml-2">
      <strong className="text-theme">{title}</strong>
    </span>
    <div className="mt-3 flex">
      Légende :
      <div className="ml-4">
        <div className="flex items-center">
          <div className="h-6 w-[100px] bg-slate-100">
            <Check color="green" className="ml-2" />
          </div>
          <span className="ml-3">Document complété, disponible pour etre consulté</span>
        </div>
        <div className="mt-3 flex items-center">
          <div className="h-6 w-[100px] border border-red-600 bg-red-100">
            <DangerSign className="ml-2" />
          </div>
          <span className="ml-3">Document à compléter</span>
        </div>
        <div className="mt-3 flex items-center">
          <div className="h-6 w-[100px] bg-[#666666]"></div>
          <span className="ml-3">Document à compléter plus tard</span>
        </div>
      </div>
    </div>
  </div>
);
