import { createReducer } from "@reduxjs/toolkit";
import { TrainingFollowUpObjectiveUpdateState } from "@redux/app-state";
import { updateTrainingFollowUpObjective } from "./update-training-follow-up-objective";

const initialState: TrainingFollowUpObjectiveUpdateState = { processing: "idle" };

export const updateTrainingFollowUpObjectiveReducer = createReducer<TrainingFollowUpObjectiveUpdateState>(initialState, builder => {
  builder.addCase(updateTrainingFollowUpObjective.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(updateTrainingFollowUpObjective.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateTrainingFollowUpObjective.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
