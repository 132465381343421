import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { TrainingFollowUpTable } from "./training-follow-up-table";
import { adminRetrieveTrainingFollowUps } from "../../../read/application/use-cases/admin/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { AppState } from "@redux/app-state";

export const TrainingFollowUpList = () => {
  const dispatch = useAppDispatch();
  const { data: trainings } = useAppSelector((state: AppState) => state.adminTrainingFollowUpsRetrieval);

  useEffect(() => {
    dispatch(adminRetrieveTrainingFollowUps());
  }, [dispatch]);

  return <TrainingFollowUpTable trainings={trainings} />;
};
