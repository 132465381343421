import { CreateTimelineEventModal } from "@academy-context/primary/admin/student-detail/create-timeline-event-modal";
import { StudentTimelineEvent, TIMELINE_EVENT_TYPE } from "@academy-context/read/domain/types/admin/student-timeline";
import { Button } from "@components/ui/button";
import { useModal } from "@hooks/useModal";
import { AddTimelineEventBody, UpdateTimelineEventBody } from "@user-management-context/shared/application/ports/admin-student-gateway";
import { Student } from "@user-management-context/read/domain/types/admin/student";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { formatDateToLocale } from "@utils/formatting";
import { Avatar, AvatarFallback } from "@components/ui/avatar";
import { ROLES_MAP } from "@user-management-context/primary/admin/user-invitations/form/form-validation/user-invitation";
import { cn } from "@components/utils/utils";
import { Pen } from "lucide-react";
import { UpdateTimelineEventModal } from "@academy-context/primary/admin/student-detail/update-timeline-event-modal";
import { useEffect, useState } from "react";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { generateInitials } from "@utils/utils";

interface Props {
  timelineEvents: StudentTimelineEvent[];
  onAddEvent: (body: AddTimelineEventBody) => void;
  onUpdateEvent: (body: UpdateTimelineEventBody) => void;
  student: Student;
}

export const StudentTimeline = ({ timelineEvents, onAddEvent, onUpdateEvent, student }: Props) => {
  const { closeModal, isModalOpen, openModal } = useModal();
  const { closeModal: closeUpdateModal, isModalOpen: isUpdateModalOpen, openModal: openUpdateModal } = useModal();
  const [eventToModify, setEventToModify] = useState<Nullable<StudentTimelineEvent>>(null);
  const [orderedEvents, setOrderedEvents] = useState<StudentTimelineEvent[]>([]);

  useEffect(() => {
    setOrderedEvents([...timelineEvents].sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime()));
  }, [timelineEvents]);

  return (
    <div className="w-full">
      <CreateTimelineEventModal isOpen={isModalOpen} onClose={closeModal} onSubmit={onAddEvent} student={student} />
      {eventToModify && (
        <UpdateTimelineEventModal
          isOpen={isUpdateModalOpen}
          onClose={closeUpdateModal}
          onSubmit={onUpdateEvent}
          student={student}
          event={eventToModify}
        />
      )}
      <div className="flex justify-center">
        <Button
          onClick={() => {
            openModal();
          }}
        >
          Ajouter un évènement
        </Button>
      </div>
      <div className="mt-4 grid grid-cols-5 space-y-2">
        {orderedEvents.map(event => (
          <Card
            key={event.title}
            className={cn([TIMELINE_EVENT_TYPE.ADMIN_NOTE].includes(event.eventType) ? "col-start-2 col-end-6" : "col-start-1 col-end-5")}
          >
            <CardHeader className="grid grid-cols-3">
              <div className="col-span-2">
                <CardTitle>{event.title}</CardTitle>
                <span className="text-sm text-muted-foreground">{formatDateToLocale(event.eventDate)}</span>
              </div>
              <div className="col-span-1 flex flex-col items-center space-y-2">
                <div className="flex w-full justify-end">
                  <Pen
                    size={15}
                    className="cursor-pointer hover:text-gray-500"
                    onClick={() => {
                      setEventToModify(event);
                      openUpdateModal();
                    }}
                  />
                </div>
                <div className="flex items-center justify-end">
                  <Avatar className="size-8 rounded-lg">
                    <AvatarFallback className="rounded-lg">{generateInitials(event.author.name, event.author.role)}</AvatarFallback>
                  </Avatar>
                  <div className="ml-2 flex flex-col">
                    <span>{event.author.name}</span>
                    <span className="text-sm text-muted-foreground">{ROLES_MAP[event.author.role]}</span>
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-sm font-normal">{event.payload.description}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};
