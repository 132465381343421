import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { resetUpdateStudentProfile } from "../../../write/application/use-cases/student/update-profile/update-student-profile";
import { retrieveStudentProfile } from "../../../read/application/use-cases/student/profile-retrieval/retrieve-student-profile";

const selectStudentProfileUpdate = (state: AppState) => state.studentProfileUpdate;

export const useStudentUserUpdate = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { fetching } = useAppSelector(selectStudentProfileUpdate);

  useEffect(() => {
    if (fetching === "success") {
      toast({ description: "Mise à jour effectuée avec succès !" });
      dispatch(resetUpdateStudentProfile());
      dispatch(retrieveStudentProfile());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la mise à jour", variant: "destructive" });
    }
  }, [fetching, dispatch, toast]);
};
