import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../config/backend";
import { ProviderTrainingFollowUpRepository } from "@shared-kernel/application/ports/provider/training-follow-up-repository";
import { TrainingFollowUpListVM, EducationalAdvisorTrainingFollowUpVM } from "@academy-context/read/domain/types/training-follow-up";

export class ProviderBackendTrainingFollowUpRepository extends HttpGateway implements ProviderTrainingFollowUpRepository {
  private _route: string = "v1/provider/training-follow-ups";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async list(): Promise<TrainingFollowUpListVM[]> {
    const { data: trainingFollowUps } = await this._instance.get(this._route);
    return trainingFollowUps;
  }

  async byId(id: string): Promise<EducationalAdvisorTrainingFollowUpVM> {
    const { data: trainingFollowUp } = await this._instance.get(`${this._route}/${id}`);
    return trainingFollowUp;
  }
}
