import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { retrieveUserProfile } from "../../../read/application/use-cases/user-profile/retrieve-user-profile";
import { resetUpdateUser } from "../../../write/application/use-cases/user-update/update-user";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

const selectUserUpdate = (state: AppState) => state.userUpdate;

export const useUserUpdate = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { fetching } = useAppSelector(selectUserUpdate);

  useEffect(() => {
    if (fetching === "success") {
      toast({ description: "Mise à jour effectuée avec succès !" });
      dispatch(resetUpdateUser());
      dispatch(retrieveUserProfile());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la mise à jour du profil", variant: "destructive" });
    }
  }, [dispatch, fetching, toast]);
};
