import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminCreateInternalTrainingBody } from "@shared-kernel/application/ports/admin/admin-internal-training-repository";

export const adminCreateInternalTraining = createAsyncThunk<string, AdminCreateInternalTrainingBody, { extra: Partial<Dependencies> }>(
  "internalTrainings/createInternalTraining",
  async (training: AdminCreateInternalTrainingBody, { extra: { adminInternalTrainingRepository } }) => {
    return adminInternalTrainingRepository!.create(training);
  }
);

export const adminResetCreateInternalTraining = createAction("internalTrainings/resetCreateInternalTraining");
