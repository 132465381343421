import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveStudentTimelineEvents } from "@academy-context/read/application/use-cases/admin/student-timeline-events-retrieval/retrieve-student-timeline-events";

const selectStudentTimelineEventUpdate = (state: AppState) => state.studentTimelineEventUpdate;
const selectStudentTimelineEventAddition = (state: AppState) => state.studentTimelineEventAddition;

export const useStudentTimelineEventAdditionOrUpdate = (studentId?: string) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing: updateProcessing } = useAppSelector(selectStudentTimelineEventUpdate);
  const { processing: additionProcessing } = useAppSelector(selectStudentTimelineEventAddition);

  useEffect(() => {
    if (updateProcessing === "success") {
      toast({ description: "Mise à jour effectuée avec succès !" });
      if (studentId) dispatch(retrieveStudentTimelineEvents(studentId));
    } else if (updateProcessing === "failed") {
      toast({ description: "Erreur lors de la mise à jour", variant: "destructive" });
    }
  }, [dispatch, studentId, toast, updateProcessing]);

  useEffect(() => {
    if (additionProcessing === "success") {
      toast({ description: "Ajout effectuée avec succès !" });
      if (studentId) dispatch(retrieveStudentTimelineEvents(studentId));
    } else if (additionProcessing === "failed") {
      toast({ description: "Erreur lors de l'ajout'", variant: "destructive" });
    }
  }, [additionProcessing, dispatch, studentId, toast]);
};
