import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { ObjectiveCard } from "./objective-card.components";
import { TrainingFollowUpObjective } from "@academy-context/read/domain/types/training-follow-up";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

interface Props {
  objectives: TrainingFollowUpObjective[];
  role: "student" | "teacher";
  trainingFollowUpId?: string;
}

export const TrainingFollowUpObjectives = ({ objectives, role, trainingFollowUpId }: Props) => {
  if (!trainingFollowUpId) return <></>;

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton
          link={`${ROLE_BASED_URLS[ROLES.ADMIN].trainingFollowUp.list}/${trainingFollowUpId}`}
          label="Suivi qualité de votre formation"
        />
        <div className="mt-5 space-y-5">
          {[...objectives]
            .sort((a, b) => a.number - b.number)
            .map(o => (
              <ObjectiveCard
                key={o.id}
                title={o.title}
                description={o.description}
                done={o.done}
                trainingFollowUpId={trainingFollowUpId}
                objectiveId={o.id}
                role={role}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
