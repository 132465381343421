import { createReducer } from "@reduxjs/toolkit";
import { adminResetUpdateInternalTraining, adminUpdateInternalTraining } from "./update-internal-training";
import { UpdateState } from "@redux/app-state";

const initialState: UpdateState = { justUpdatedId: null, processing: "idle" };

export const adminUpdateInternalTrainingReducer = createReducer<UpdateState>(initialState, builder => {
  builder.addCase(adminUpdateInternalTraining.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedId: meta.arg.trainingId,
      processing: "success",
    };
  });
  builder.addCase(adminUpdateInternalTraining.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(adminUpdateInternalTraining.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(adminResetUpdateInternalTraining, () => {
    return initialState;
  });
});
