import { createReducer } from "@reduxjs/toolkit";
import { retrieveTrainingFollowUpSessions } from "./retrieve-training-follow-up-sessions";
import { TrainingFollowUpSessionsRetrievalState } from "@redux/app-state";

const initialState: TrainingFollowUpSessionsRetrievalState = { fetching: "idle", data: [] };

export const retrieveTrainingFollowUpSessionsReducer = createReducer<TrainingFollowUpSessionsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveTrainingFollowUpSessions.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTrainingFollowUpSessions.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTrainingFollowUpSessions.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
