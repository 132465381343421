import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "src/redux/app-state";
import { adminRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/admin/training-follow-ups-retrieval/retrieve-training-follow-ups";

const selectTrainingDunningsToggle = (state: AppState) => state.trainingFollowUpDunningsStatusToggle;

export const useTrainingFollowUpUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectTrainingDunningsToggle);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Suivi mis à jour !" });
      dispatch(adminRetrieveTrainingFollowUps());
    } else if (processing === "failed") {
      toast({
        description: "Erreur lors de la mise à jour du suivi. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [dispatch, processing, toast]);
};
