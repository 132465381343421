import { array, number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { InternalTraining } from "@academy-context/read/domain/types/shared/internal-training";

export type InternalTrainingFormInputs = {
  title: string;
  tag: string;
  objective: string;
  objectiveText: string;
  description: string;
  descriptionText: string;
  team: string;
  questions: { text: string; order: number }[];
  price?: number;
};

export const internalTrainingdefaultValues = {
  title: "Nouvelle formation",
  tag: "",
  objective: "",
  objectiveText: "",
  description: "",
  descriptionText: "",
  team: "",
  price: undefined,
  questions: [
    {
      text: "",
      order: 1,
    },
    {
      text: "",
      order: 2,
    },
    {
      text: "",
      order: 3,
    },
  ],
};

export const internalTrainingSchema = object().shape({
  title: string().max(255).required(REQUIRED_FIELD),
  tag: string().max(255).required(REQUIRED_FIELD),
  objective: string().max(8000).required(REQUIRED_FIELD),
  objectiveText: string().max(8000).required(REQUIRED_FIELD),
  description: string().max(8000).required(REQUIRED_FIELD),
  descriptionText: string().max(8000).required(REQUIRED_FIELD),
  team: string().max(255).required(REQUIRED_FIELD),
  price: number().min(1).max(4000).required(REQUIRED_FIELD),
  questions: array()
    .min(3, "Requiert au moins une question")
    .of(
      object().shape({
        text: string().max(255).required(REQUIRED_FIELD),
        order: number().required(REQUIRED_FIELD),
      })
    ),
});

export const formatInternalTrainingDataToFormData = (training: InternalTraining): InternalTrainingFormInputs => {
  const { tag, title, objective, description, team, price, questions } = training;

  return {
    tag,
    title,
    objective,
    objectiveText: objective,
    description,
    descriptionText: description,
    team,
    price,
    questions,
  };
};

export const formatFormDataToBodyData = (training: InternalTrainingFormInputs): Omit<InternalTraining, "id" | "creationDate"> => {
  const { tag, title, objective, description, team, price, questions } = training;

  return {
    tag,
    title,
    objective,
    description,
    team,
    price: price ?? 0,
    questions,
  };
};
