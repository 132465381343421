import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveProviderTrainings } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/retrieve-provider-trainings";
import { resetDeleteProviderTraining } from "@academy-context/write/application/use-cases/provider/provider-training-deletion/delete-provider-training";

export const selectProviderTrainingDeleted = (state: AppState) => state.providerTrainingDeletion;

export const useProviderTrainingDeleted = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectProviderTrainingDeleted);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Formation supprimée !" });
      dispatch(resetDeleteProviderTraining());
      dispatch(retrieveProviderTrainings());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la suppression de la formation", variant: "destructive" });
    }
  }, [dispatch, processing, toast]);
};
