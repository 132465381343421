import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { AppState } from "src/redux/app-state";
import { retrieveUserProfile } from "@user-management-context/read/application/use-cases/user-profile/retrieve-user-profile";
import { retrieveFundingRequestEligibility } from "@academy-context/read/application/use-cases/student/funding-request-eligibility-retrieval/retrieve-funding-request-eligibility";
import { studentRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/student/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { teacherRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/teacher/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { retrieveStudentProfile } from "@user-management-context/read/application/use-cases/student/profile-retrieval/retrieve-student-profile";
import { selectStudentProfileRetrieval } from "@user-management-context/read/application/use-cases/student/profile-retrieval/selectors/student-profile-selectors";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { providerRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/provider/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarRail, useSidebar } from "@components/ui/sidebar";
import { NavHeader } from "@shared-kernel/primary/layout/side-bar/side-bar-header";
import { NavUser } from "@shared-kernel/primary/layout/side-bar/nav-user";
import { AdminSideBar } from "@shared-kernel/primary/layout/side-bar/admin-side-bar";
import { cn } from "@components/utils/utils";
import { StandardUserSideBar } from "@shared-kernel/primary/layout/side-bar/standard-user-side-bar";

interface Props {
  logout: () => void;
}

export const SideBar = ({ logout }: Props) => {
  const dispatch = useAppDispatch();
  const { data: user } = useAppSelector((state: AppState) => state.userProfileRetrieval);
  const { data: studentTrainings } = useAppSelector((state: AppState) => state.studentTrainingFollowUpsRetrieval);
  const { data: teacherTrainings } = useAppSelector((state: AppState) => state.teacherTrainingFollowUpsRetrieval);
  const { data: providerTrainingFollowUps } = useAppSelector((state: AppState) => state.providerTrainingFollowUpsRetrieval);
  const student = useAppSelector(selectStudentProfileRetrieval);
  const { state } = useSidebar();

  const isStudent = user?.role === ROLES.STUDENT;
  const isTeacher = user?.role === ROLES.TEACHER;
  const isProvider = user?.role === ROLES.PROVIDER;
  const isAdmin = user?.role === ROLES.ADMIN;

  useEffect(() => {
    dispatch(retrieveUserProfile());
    if (isStudent) dispatch(retrieveStudentProfile());
  }, [dispatch, isStudent]);

  const studentIsAvailableAction = useMemo(() => studentTrainings.some(t => t.isAvailableAction), [studentTrainings]);
  const teacherIsAvailableAction = useMemo(() => teacherTrainings.some(t => t.isAvailableAction), [teacherTrainings]);
  const providerIsAvailableAction = useMemo(() => providerTrainingFollowUps.some(t => t.isAvailableAction), [providerTrainingFollowUps]);

  useEffect(() => {
    if (isStudent) {
      dispatch(retrieveFundingRequestEligibility());
      dispatch(studentRetrieveTrainingFollowUps());
    }
  }, [dispatch, isStudent]);

  useEffect(() => {
    if (isTeacher) {
      dispatch(teacherRetrieveTrainingFollowUps());
    }
  }, [dispatch, isTeacher]);

  useEffect(() => {
    if (isProvider) {
      dispatch(providerRetrieveTrainingFollowUps());
    }
  }, [dispatch, isProvider]);

  const onLogout = () => {
    logout();
  };

  const NavBar = isAdmin ? (
    <AdminSideBar />
  ) : (
    <StandardUserSideBar
      role={user?.role ?? null}
      student={student}
      isActionAvailable={studentIsAvailableAction || teacherIsAvailableAction || providerIsAvailableAction}
    />
  );

  return (
    <Sidebar collapsible="icon" variant="sidebar">
      <SidebarHeader className={cn("py-5", state === "collapsed" && "invisible")}>
        <NavHeader user={user} />
      </SidebarHeader>
      <SidebarContent className="gap-0">{NavBar}</SidebarContent>
      <SidebarFooter>
        <NavUser user={user} onLogout={onLogout} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
};
