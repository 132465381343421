import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const toggleTrainingFollowUpDunningsStatus = createAsyncThunk<
  void,
  { id: string; action: "pause" | "resume" },
  { extra: Partial<Dependencies> }
>(
  "trainingFollowUps/toggleTrainingFollowUpDunningsStatus",
  async ({ id, action }: { id: string; action: "pause" | "resume" }, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.toggleTrainingFollowUpDunningProcessStatus({ trainingId: id, action });
  }
);
