import { createReducer } from "@reduxjs/toolkit";
import { adminTrainingFollowUpsRetrievalState } from "@redux/app-state";
import { adminRetrieveTrainingFollowUps } from "./retrieve-training-follow-ups";

const initialState: adminTrainingFollowUpsRetrievalState = { fetching: "idle", data: [] };

export const adminRetrieveTrainingFollowUpsReducer = createReducer<adminTrainingFollowUpsRetrievalState>(initialState, builder => {
  builder.addCase(adminRetrieveTrainingFollowUps.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveTrainingFollowUps.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveTrainingFollowUps.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
