import { AttendanceSheetForm } from "./attendance-sheet-form";
import { EducationalAdvisorTrainingFollowUpVM, TrainingFollowUpSession } from "@academy-context/read/domain/types/training-follow-up";
import { Nullable } from "@shared-kernel/core/types/nullable";

interface Props {
  trainingFollowUpId?: string;
  isAdmin?: boolean;
  trainingFollowUpSessions: TrainingFollowUpSession[];
  trainingFollowUp: Nullable<EducationalAdvisorTrainingFollowUpVM>;
}

export const TeacherAttendanceSheetForm = ({ trainingFollowUp, trainingFollowUpSessions, isAdmin = false, trainingFollowUpId }: Props) => {
  if (trainingFollowUpSessions.length === 0 || !trainingFollowUp || !trainingFollowUpId) return <></>;

  const formattedAttendanceSheets = trainingFollowUpSessions.map(s => ({
    id: s.id,
    scheduledDate: new Date(s.scheduledDate),
    signatureUrl: s.teacherSignatureUrl,
    number: s.number,
  }));

  return (
    <AttendanceSheetForm
      role="teacher"
      title={`${trainingFollowUp.title} | ${trainingFollowUp.student} | ${trainingFollowUp.externalFundingRequestId}`}
      conventionDate={new Date(trainingFollowUp.date)}
      attendanceSheets={formattedAttendanceSheets}
      trainingFollowUpId={trainingFollowUp.id}
      isAdmin={isAdmin}
    />
  );
};
