import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStudentAccountCreate } from "./use-student-create";
import { createStudentAccount } from "../../../write/application/use-cases/student/student-account-creation/create-student-account";
import { retrieveStudentPublicProfile } from "../../../read/application/use-cases/student/public-profile-retrieval/retrieve-student-public-profile";
import { AppState } from "@redux/app-state";
import { verifyTokenValidity } from "../../../write/application/use-cases/token-verification/verify-token-validity";
import { TokenStatus } from "../../../shared/domain/types/enums/token";
import { PhoneInput } from "../../shared/phone-input/phone-input";
import {
  StudentAccountCreationFormInputs,
  schema,
  defaultValues,
  studentAccountCreationFormatDataToFormData,
  studentAccountCreationFormatFormDataToBodyData,
} from "./form-validation/student";
import { generateMessage } from "../../shared/account-creation";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { PasswordInput } from "../../shared/password-input/password-input.components";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { ScrollArea } from "@shared-kernel/primary/shared/shadcn/ui/scroll-area";
import { ThemedButton } from "@shared-kernel/primary/shared/themed-button/themed-button";

export const StudentAccountCreation = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");

  const { data: student } = useAppSelector((state: AppState) => state.studentPublicProfileRetrieval);
  const { data: tokenFromDB } = useAppSelector((state: AppState) => state.tokenValidityCheck);

  const [tokenStatus, setTokenStatus] = useState<TokenStatus>("invalid");

  const methods = useForm<StudentAccountCreationFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
    context: { validation: "student" },
  });

  const form = methods;
  const {
    reset,
    formState: { isDirty },
    setValue,
    watch,
  } = form;

  useEffect(() => {
    if (token && userId) dispatch(verifyTokenValidity({ userId, token }));
  }, [dispatch, token, userId]);

  useEffect(() => {
    if (tokenFromDB) setTokenStatus(tokenFromDB.status);
  }, [tokenFromDB]);

  useEffect(() => {
    if (tokenStatus === "valid" && userId) dispatch(retrieveStudentPublicProfile(userId));
  }, [dispatch, userId, tokenStatus]);

  useEffect(() => {
    if (student) reset({ ...studentAccountCreationFormatDataToFormData(student), password: "" });
  }, [reset, student]);

  useStudentAccountCreate();

  const handleOnSubmit = async (formBody: StudentAccountCreationFormInputs) => {
    const body = studentAccountCreationFormatFormDataToBodyData(formBody, userId as string, token as string);

    await dispatch(createStudentAccount(body));
  };

  const phone = watch("mobile");

  if (tokenStatus !== "valid") return generateMessage(tokenStatus);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleOnSubmit)}>
        <ScrollArea className="h-[500px]" displayScrollBar={true}>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nom de famille</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobile"
              render={() => (
                <FormItem>
                  <FormLabel>Téléphone</FormLabel>
                  <FormControl>
                    <PhoneInput
                      phone={phone}
                      onChange={value => {
                        setValue("mobile", value as string, { shouldDirty: true });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-2">
              <Label>Email</Label>
              <Input value={student?.email || ""} disabled />
            </div>
            <PasswordInput control={form.control} password={watch("password")} />
            <div className="col-span-2 mb-10 flex justify-end">
              <ThemedButton isDirty={isDirty} label="Créer compte" />
            </div>
          </div>
        </ScrollArea>
      </form>
    </Form>
  );
};
