import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AddTimelineEventBody } from "@user-management-context/shared/application/ports/admin-student-gateway";

export const addStudentTimelineEvent = createAsyncThunk<void, AddTimelineEventBody, { extra: Partial<Dependencies> }>(
  "timelineEvents/addStudentTimelineEvent",
  async (body: AddTimelineEventBody, { extra: { adminStudentGateway } }) => {
    return adminStudentGateway!.addStudentTimelineEvent(body);
  }
);

export const resetAddStudentTimelineEvent = createAction("timelineEvents/resetAddStudentTimelineEvent");
