import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { updateTrainingFollowUpObjectiveBody } from "@academy-context/write/domain/types/training-follow-up";

export type FormInputs = {
  done: string;
};

export const defaultValues: FormInputs = {
  done: "",
};

export const schema = object().shape({
  done: string().required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (objective: FormInputs): updateTrainingFollowUpObjectiveBody => {
  return {
    done: objective.done,
  };
};
