import { createReducer } from "@reduxjs/toolkit";
import { retrieveStudentsForSearchBar } from "./retrieve-students-for-search-bar";
import { StudentListElement } from "@user-management-context/read/domain/types/admin/student";
import { RetrievalState } from "@redux/app-state";

const initialState: RetrievalState<StudentListElement[]> = { fetching: "idle", data: [] };

export const retrieveStudentsForSearchBarReducer = createReducer<RetrievalState<StudentListElement[]>>(initialState, builder => {
  builder.addCase(retrieveStudentsForSearchBar.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveStudentsForSearchBar.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveStudentsForSearchBar.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
