import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { providerRetrieveTrainingFollowUp } from "@academy-context/read/application/use-cases/provider/training-follow-up-retrieval/retrieve-training-follow-up";
import { TrainingFollowUpDetailComponent } from "@academy-context/primary/shared/training-follow-up/training-follow-up-detail.components";

export const ProviderTrainingFollowUpDetailContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: trainingFollowUp } = useAppSelector((state: AppState) => state.providerTrainingFollowUpRetrieval);

  useEffect(() => {
    if (id) dispatch(providerRetrieveTrainingFollowUp(id));
  }, [dispatch, id]);

  return <TrainingFollowUpDetailComponent trainingFollowUp={trainingFollowUp} />;
};
