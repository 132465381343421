import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { providerRetrieveTrainingFollowUps, resetProviderRetrieveTrainingFollowUps } from "./retrieve-training-follow-ups";
import { TrainingFollowUpListVM } from "@academy-context/read/domain/types/training-follow-up";

const initialState: RetrievalState<TrainingFollowUpListVM[]> = { fetching: "idle", data: [] };

export const providerRetrieveTrainingFollowUpsReducer = createReducer<RetrievalState<TrainingFollowUpListVM[]>>(initialState, builder => {
  builder.addCase(providerRetrieveTrainingFollowUps.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(providerRetrieveTrainingFollowUps.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(providerRetrieveTrainingFollowUps.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetProviderRetrieveTrainingFollowUps, () => {
    return initialState;
  });
});
