import { Dependencies } from "@redux/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateTimelineEventBody } from "@user-management-context/shared/application/ports/admin-student-gateway";

export const updateStudentTimelineEvent = createAsyncThunk<void, UpdateTimelineEventBody, { extra: Partial<Dependencies> }>(
  "timelineEvents/updateStudentTimelineEvent",
  async (body: UpdateTimelineEventBody, { extra: { adminStudentGateway } }) => {
    return adminStudentGateway!.updateStudentTimelineEvent(body);
  }
);

export const resetUpdateStudentTimelineEvent = createAction("timelineEvents/resetUpdateStudentTimelineEvent");
