import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { EducationalAdvisorTrainingFollowUpList } from "@academy-context/primary/shared/training-follow-up/training-follow-up-list.components";
import { teacherRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/teacher/training-follow-ups-retrieval/retrieve-training-follow-ups";

export const TeacherTrainingFollowUpListContainer = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: AppState) => state.teacherTrainingFollowUpsRetrieval);

  useEffect(() => {
    dispatch(teacherRetrieveTrainingFollowUps());
  }, [dispatch]);

  return <EducationalAdvisorTrainingFollowUpList trainingFollowUps={data} isTeacher />;
};
