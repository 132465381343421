import { TeacherTrainingFollowUpListContainer } from "@academy-context/primary/teacher/training-follow-up/training-follow-up-list.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const TeacherTrainingFollowUpListPage = () => {
  return (
    <Page title="Suivi Qualiopi">
      <TeacherTrainingFollowUpListContainer />
    </Page>
  );
};
