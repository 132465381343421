import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { EducationalAdvisorTrainingFollowUpVM } from "../../../../domain/types/training-follow-up";

export const teacherRetrieveTrainingFollowUp = createAsyncThunk<
  EducationalAdvisorTrainingFollowUpVM,
  string,
  { extra: Partial<Dependencies> }
>("trainingFollowUps/teacherRetrieveTrainingFollowUp", async (id: string, { extra: { trainingFollowUpGateway } }) => {
  return trainingFollowUpGateway!.teacherGetOne(id);
});
