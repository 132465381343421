import { createReducer } from "@reduxjs/toolkit";
import { StudentTrainingRetrievalState } from "@redux/app-state";
import { studentRetrieveTrainingFollowUp } from "./retrieve-training-follow-up";

const initialState: StudentTrainingRetrievalState = { fetching: "idle", data: null };

export const studentRetrieveTrainingFollowUpReducer = createReducer<StudentTrainingRetrievalState>(initialState, builder => {
  builder.addCase(studentRetrieveTrainingFollowUp.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(studentRetrieveTrainingFollowUp.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(studentRetrieveTrainingFollowUp.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
