import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Separator } from "@components/ui/separator";
import { adminRetrieveInternalTrainings } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/retrieve-internal-trainings";
import { InternalTrainingListComponent } from "@academy-context/primary/admin/internal-trainings/list/internal-training-list.components";
import { InternalTrainingListSkeleton } from "@academy-context/primary/admin/internal-trainings/list/internal-training-list.skeleton";
import { adminSelectInternalTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/selectors/internal-training-list-selectors";
import {
  AdminCreateInternalTrainingBody,
  AdminUpdateInternalTrainingBody,
} from "@shared-kernel/application/ports/admin/admin-internal-training-repository";
import { adminCreateInternalTraining } from "@academy-context/write/application/use-cases/admin/internal-training-creation/create-internal-training";
import { adminUpdateInternalTraining } from "@academy-context/write/application/use-cases/admin/internal-training-update/update-internal-training";
import { adminDeleteInternalTraining } from "@academy-context/write/application/use-cases/admin/internal-training-deletion/delete-internal-training";
import { useInternalTrainingCreated } from "@academy-context/primary/admin/internal-trainings/list/use-training-created";
import { useInternalTrainingUpdated } from "@academy-context/primary/admin/internal-trainings/list/use-training-updated";
import { useInternalTrainingDeleted } from "@academy-context/primary/admin/internal-trainings/list/use-training-deleted";

export const AdminInternalTrainingListContainer = () => {
  const dispatch = useAppDispatch();
  const { trainings, isLoading } = useAppSelector(adminSelectInternalTrainingsRetrievalForList);

  useEffect(() => {
    dispatch(adminRetrieveInternalTrainings());
  }, [dispatch]);

  useInternalTrainingCreated();
  useInternalTrainingUpdated();
  useInternalTrainingDeleted();

  const onCreate = (body: AdminCreateInternalTrainingBody) => {
    dispatch(adminCreateInternalTraining(body));
  };
  const onUpdate = (body: AdminUpdateInternalTrainingBody) => {
    dispatch(adminUpdateInternalTraining(body));
  };

  const onDelete = (trainingId: string) => {
    dispatch(adminDeleteInternalTraining(trainingId));
  };

  return (
    <>
      <h1 className="text-5xl font-bold">Formations</h1>
      <h2 className="mt-2 text-xs font-light">
        Liste des formations proposées par OPERA Off. <br />
        Les informations de cette section sont utilisées pour générer les devis qualitatifs et quantitatifs qui seront envoyés aux
        financeurs.
      </h2>
      <Separator className="mt-3" />
      <div className="mt-3">
        {isLoading ? (
          <InternalTrainingListSkeleton />
        ) : (
          <InternalTrainingListComponent trainings={trainings} onCreate={onCreate} onUpdate={onUpdate} onDelete={onDelete} />
        )}
      </div>
    </>
  );
};
