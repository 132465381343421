import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingFollowUpSession } from "../../../../domain/types/training-follow-up";

export const retrieveTrainingFollowUpSessions = createAsyncThunk<TrainingFollowUpSession[], string, { extra: Partial<Dependencies> }>(
  "trainingSessions/retrieveTrainingFollowUpSessions",
  async (trainingId: string, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.getTrainingFollowUpSessions(trainingId);
  }
);
