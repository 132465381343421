import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingFollowUpObjective } from "../../../../domain/types/training-follow-up";

export const retrieveTrainingFollowUpObjectives = createAsyncThunk<
  TrainingFollowUpObjective[],
  { trainingId: string; role: "teacher" | "student" },
  { extra: Partial<Dependencies> }
>(
  "trainingObjectives/retrieveTrainingFollowUpObjectives",
  async ({ trainingId, role }: { trainingId: string; role: "teacher" | "student" }, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.getTrainingFollowUpObjectives(trainingId, role);
  }
);
