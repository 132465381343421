import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveProvider } from "@user-management-context/read/application/use-cases/admin/provider-retrieval/retrieve-provider";
import { resetUpdateProvider } from "@user-management-context/write/application/use-cases/admin/provider-update/update-provider";

const selectProviderUpdate = (state: AppState) => state.providerUpdate;

export const useProviderUpdate = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedId, processing } = useAppSelector(selectProviderUpdate);

  useEffect(() => {
    if (justUpdatedId && processing === "success") {
      toast({ description: "Mise à jour effectuée avec succès !" });
      dispatch(resetUpdateProvider());
      dispatch(retrieveProvider(justUpdatedId));
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la mise à jour du prestataire", variant: "destructive" });
    }
  }, [justUpdatedId, processing, dispatch, toast]);
};
