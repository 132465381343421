import { ProviderTrainingFollowUpObjectivesContainer } from "@academy-context/primary/provider/training-follow-up/training-follow-up-objectives.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const ProviderTrainingFollowUpObjectivesPage = () => {
  return (
    <Page title="Suivi Qualiopi">
      <ProviderTrainingFollowUpObjectivesContainer />
    </Page>
  );
};
