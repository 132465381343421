import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminResetCreateInternalTraining } from "@academy-context/write/application/use-cases/admin/internal-training-creation/create-internal-training";
import { adminRetrieveInternalTrainings } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/retrieve-internal-trainings";

export const selectInternalTrainingCreated = (state: AppState) => state.adminInternalTrainingCreation;

export const useInternalTrainingCreated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedId, processing } = useAppSelector(selectInternalTrainingCreated);

  useEffect(() => {
    if (processing === "success" && justCreatedId) {
      toast({ description: "Formation ajoutée !" });
      dispatch(adminResetCreateInternalTraining());
      dispatch(adminRetrieveInternalTrainings());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'ajout de la formation", variant: "destructive" });
    }
  }, [dispatch, justCreatedId, processing, toast]);
};
