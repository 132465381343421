import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentTrainingFollowUpVM } from "../../../../domain/types/training-follow-up";

export const studentRetrieveTrainingFollowUp = createAsyncThunk<StudentTrainingFollowUpVM, string, { extra: Partial<Dependencies> }>(
  "trainingFollowUps/studentRetrieveTrainingFollowUp",
  async (id: string, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.studentGetOne(id);
  }
);
