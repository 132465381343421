import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

export enum TIMELINE_EVENT_TITLE {
  PHONE_CALL = "Appel téléphonique",
  EMAIL_EXCHANGE = "Echange d'email",
  SMS = "SMS",
}

export enum TIMELINE_EVENT_TYPE {
  ADMIN_NOTE = "admin_note",
}

export type TIMELINE_EVENT = { eventType: TIMELINE_EVENT_TYPE.ADMIN_NOTE; payload: { description: string } };

export type StudentTimelineEvent = {
  id: string;
  studentId: string;
  title: string;
  eventDate: string;
  creationDate: string;
  author: {
    role: ROLES;
    accountId: string;
    name: string;
  };
} & TIMELINE_EVENT;
