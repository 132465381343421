import { isLoadingState } from "@utils/utils";
import { Student } from "../../../../../domain/types/admin/student";
import { AppState } from "@redux/app-state";

export const selectStudentRetrieval = (state: AppState) => {
  const { data, fetching } = state.studentRetrieval;

  let student: Student = {} as Student;
  if (fetching === "success") {
    student = data!;
  }

  return { student, isLoading: isLoadingState(fetching) };
};
