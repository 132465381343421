import { Button } from "@components/ui/button";
import { PropsWithChildren } from "react";

export const ThemedButton = ({ isDirty, label, children }: PropsWithChildren<{ isDirty: boolean; label?: string }>) => {
  return (
    <Button
      type="submit"
      disabled={!isDirty}
      className="ml-2 bg-theme hover:bg-theme hover:text-gray-300 dark:text-white dark:hover:text-gray-300"
    >
      {label || children}
    </Button>
  );
};
