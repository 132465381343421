import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingFollowUpListVM } from "../../../../domain/types/training-follow-up";

export const teacherRetrieveTrainingFollowUps = createAsyncThunk<TrainingFollowUpListVM[], void, { extra: Partial<Dependencies> }>(
  "trainingFollowUps/teacherRetrieveTrainingFollowUps",
  async (_: void, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.teacherGetAll();
  }
);

export const resetTeacherRetrieveTrainingFollowUps = createAction("trainingFollowUps/resetTeacherRetrieveTrainingFollowUps");
