import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useNavigate } from "react-router-dom";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { ROLE_BASED_URLS } from "src/routes";

const selectFundingRequestReview = (state: AppState) => state.fundingRequestReview;

export const useFundingRequestReviewed = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectFundingRequestReview);

  useEffect(() => {
    if (processing === "success") {
      navigate(ROLE_BASED_URLS[ROLES.ADMIN].fundingRequest.list);
      toast({ description: "Demande de financement validée!" });
    } else if (processing === "failed") {
      toast({
        description: "Erreur lors de la validation de la demande de financement. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [navigate, dispatch, processing, toast]);
};
