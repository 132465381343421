import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import {
  resetUpdateStudentTimelineEvent,
  updateStudentTimelineEvent,
} from "@academy-context/write/application/use-cases/admin/student-timeline-event-update/update-student-timeline-event";

const initialState: SimpleState = { processing: "idle" };

export const updateStudentTimelineEventReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(updateStudentTimelineEvent.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(updateStudentTimelineEvent.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateStudentTimelineEvent.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUpdateStudentTimelineEvent, () => {
    return initialState;
  });
});
