import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { toggleTrainingFollowUpDunningsStatus } from "./toggle-training-follow-up-dunnings-status";

const initialState: SimpleState = { processing: "idle" };

export const toggleTrainingFollowUpDunningsStatusReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(toggleTrainingFollowUpDunningsStatus.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(toggleTrainingFollowUpDunningsStatus.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(toggleTrainingFollowUpDunningsStatus.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
