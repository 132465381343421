import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentTimelineEvent } from "@academy-context/read/domain/types/admin/student-timeline";

export const retrieveStudentTimelineEvents = createAsyncThunk<StudentTimelineEvent[], string, { extra: Partial<Dependencies> }>(
  "timelineEvents/retrieveStudentTimelineEvents",
  async (studentId: string, { extra: { adminStudentGateway } }) => {
    return adminStudentGateway!.timelineEvents(studentId);
  }
);
