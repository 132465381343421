import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { StudentTimelineEvent } from "@academy-context/read/domain/types/admin/student-timeline";
import { retrieveStudentTimelineEvents } from "@academy-context/read/application/use-cases/admin/student-timeline-events-retrieval/retrieve-student-timeline-events";

const initialState: RetrievalState<StudentTimelineEvent[]> = { fetching: "idle", data: [] };

export const retrieveStudentTimelineEventsReducer = createReducer<RetrievalState<StudentTimelineEvent[]>>(initialState, builder => {
  builder.addCase(retrieveStudentTimelineEvents.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveStudentTimelineEvents.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveStudentTimelineEvents.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
