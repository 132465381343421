import { createReducer } from "@reduxjs/toolkit";
import { adminRetrieveTrainingFollowUp } from "./retrieve-training-follow-up";
import { deleteSignedConvention } from "../../../../../write/application/use-cases/admin/signed-convention-deletion/delete-signed-convention";
import { AdminTrainingFollowUpVM } from "@academy-context/read/domain/types/training-follow-up";
import { RetrievalState } from "@redux/app-state";
import { Nullable } from "@shared-kernel/core/types/nullable";

const initialState: RetrievalState<Nullable<AdminTrainingFollowUpVM>> = { fetching: "idle", data: null };

export const adminRetrieveTrainingFollowUpReducer = createReducer<RetrievalState<Nullable<AdminTrainingFollowUpVM>>>(
  initialState,
  builder => {
    builder.addCase(adminRetrieveTrainingFollowUp.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(adminRetrieveTrainingFollowUp.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(adminRetrieveTrainingFollowUp.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
    builder.addCase(deleteSignedConvention.fulfilled, state => {
      if (state.data)
        return {
          ...state,
          data: {
            ...state.data,
            teacher: {
              ...state.data.teacher,
              convention: {
                ...state.data.teacher.convention,
                signedUrl: null,
              },
            },
          },
        };

      return { ...state };
    });
  }
);
