import { AdminStudentListItem } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/students-selectors";
import { USER_STATUS } from "@user-management-context/read/domain/types/admin/student";
import { FileClock, MailCheck, MailX, UserCheck, UserX } from "lucide-react";

export const statusFormatter = (student: AdminStudentListItem) => {
  let icon = <UserX color="red" />;
  if (student.isFundingRequestInProgress) icon = <FileClock />;
  else if (student.userStatus === USER_STATUS.EXPIRED_INVITATION) icon = <MailX color="red" />;
  else if (student.userStatus === USER_STATUS.INVITATION_SENT) icon = <MailCheck />;
  else if (student.userStatus === USER_STATUS.REGISTERED) {
    icon = <UserCheck color="green" />;
  }
  return <div className="flex justify-center">{icon}</div>;
};
