import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useForm } from "react-hook-form";
import { FormInputs, schema, defaultValues, teacherFormatFormDataToBodyData } from "./form-validation/teacher";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTeacherAccountCreate } from "./use-teacher-create";
import { AppState } from "@redux/app-state";
import { formatTeacherDataToFormData } from "./form-validation/teacher";
import { verifyTokenValidity } from "../../../write/application/use-cases/token-verification/verify-token-validity";
import { TokenStatus } from "../../../shared/domain/types/enums/token";
import { retrieveTeacherPublicProfile } from "../../../read/application/use-cases/teacher/public-profile-retrieval/retrieve-teacher-public-profile";
import { createTeacherAccount } from "../../../write/application/use-cases/teacher/account-creation/create-teacher-account";
import { PhoneInput } from "../../shared/phone-input/phone-input";
import { MAX_HOURLY_PRICE, TEACHER_MIN_HOURLY_PRICE } from "../../../../academy-context/write/domain/constants/shared";
import { generateMessage } from "../../shared/account-creation";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { ScrollArea } from "@shared-kernel/primary/shared/shadcn/ui/scroll-area";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { PasswordInput } from "../../shared/password-input/password-input.components";
import { ThemedButton } from "@shared-kernel/primary/shared/themed-button/themed-button";

export const TeacherAccountCreation = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");
  const { data: teacher } = useAppSelector((state: AppState) => state.teacherPublicProfileRetrieval);
  const { data: tokenFromDB } = useAppSelector((state: AppState) => state.tokenValidityCheck);
  const [tokenStatus, setTokenStatus] = useState<TokenStatus>("invalid");
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues, context: { validation: "teacher" } });

  const {
    reset,
    formState: { isDirty },
    watch,
    setValue,
  } = form;

  useEffect(() => {
    if (token && userId) dispatch(verifyTokenValidity({ userId, token }));
  }, [dispatch, token, userId]);

  useEffect(() => {
    if (tokenFromDB) setTokenStatus(tokenFromDB.status);
  }, [tokenFromDB]);

  useEffect(() => {
    if (tokenStatus === "valid" && userId) dispatch(retrieveTeacherPublicProfile(userId));
  }, [dispatch, userId, tokenStatus]);

  useEffect(() => {
    if (teacher) reset({ ...formatTeacherDataToFormData(teacher), password: "" });
  }, [teacher, reset]);

  useTeacherAccountCreate();

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = teacherFormatFormDataToBodyData(formBody, userId as string, token as string);

    await dispatch(createTeacherAccount(body));
  };

  const phone = watch("mobile");

  if (tokenStatus !== "valid") return generateMessage(tokenStatus);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleOnSubmit)}>
        <ScrollArea className="h-[500px]" displayScrollBar={true}>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nom de famille</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobile"
              render={() => (
                <FormItem>
                  <FormLabel>Téléphone</FormLabel>
                  <FormControl>
                    <PhoneInput
                      phone={phone}
                      onChange={value => {
                        setValue("mobile", value as string, { shouldDirty: true });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-2">
              <Label>Email</Label>
              <Input value={teacher?.email || ""} disabled />
            </div>
            <FormField
              control={form.control}
              name="siret"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SIRET (si applicable)</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IBAN (zone Euro)</FormLabel>
                  <FormControl>
                    <Input placeholder={`Exemple : FR76 4061 8803 0000 0408 3645 139`} {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="activityRegistrationNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Numéro de déclaration d'activité</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="defaultPrice"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tarif horaire standard (€/heure)</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      step="1"
                      min={TEACHER_MIN_HOURLY_PRICE}
                      max={MAX_HOURLY_PRICE}
                      {...field}
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Adresse complète du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="additionalAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ville et code postal du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <PasswordInput control={form.control} password={watch("password")} />
            <div className="col-span-2 mb-10 flex justify-end">
              <ThemedButton isDirty={isDirty} label="Créer compte" />
            </div>
          </div>
        </ScrollArea>
      </form>
    </Form>
  );
};
