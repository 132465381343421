import { CommandInput, CommandGroup, CommandItem, CommandList, CommandEmpty } from "@components/ui/command";
import { CommandLoading, Command } from "cmdk";
import { useState, useRef, useCallback, type KeyboardEvent, ReactElement, useMemo, useEffect } from "react";
import { cn } from "@components/utils/utils";
import { Skeleton } from "@components/ui/skeleton";
import { getNormalizedString } from "@utils/utils";
import Fuse from "fuse.js";

export type Option = {
  value: string;
  display: ReactElement;
};

type AutoCompleteProps = {
  options: Option[];
  emptyMessage: string;
  isLoading: boolean;
  placeholder: string;
};

const fuseOptions = {
  keys: ["value"],
};

export const CommandSearch = ({ options, placeholder, emptyMessage, isLoading }: AutoCompleteProps) => {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const scrollableRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (!input) {
        return;
      }

      // Keep the options displayed when the user is typing
      if (!isOpen) {
        setOpen(true);
      }

      if (event.key === "Escape") {
        input.blur();
      }
    },
    [isOpen]
  );

  const handleBlur = useCallback(() => {
    setOpen(false);
    setInputValue("");
  }, []);

  const handleSelectOption = useCallback(() => {
    // This is a hack to prevent the input from being focused after the user selects an option
    // We can call this hack: "The next tick"
    setTimeout(() => {
      inputRef?.current?.blur();
    }, 0);
  }, []);

  const fuse = useMemo(() => new Fuse(options, fuseOptions), [options]);

  useEffect(() => {
    const normalizedInputValue = getNormalizedString(inputValue);

    if (!normalizedInputValue) {
      setFilteredOptions(options);
    } else {
      const results = fuse.search(normalizedInputValue);
      setFilteredOptions(results.map(result => result.item));
    }
  }, [fuse, inputValue, isOpen, options]);

  useEffect(() => {
    scrollableRef.current?.scrollTo(0, 0);
  }, [filteredOptions, isOpen]);

  return (
    <Command onKeyDown={handleKeyDown} shouldFilter={false}>
      <CommandInput
        ref={inputRef}
        value={inputValue}
        onValueChange={isLoading ? undefined : setInputValue}
        onBlur={handleBlur}
        onFocus={() => setOpen(true)}
        placeholder={placeholder}
        disabled={false}
        className="md:min-w-[450px]"
        containerClassName="rounded-full"
      />
      <div className="relative mt-1">
        <div
          className={cn(
            "animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none",
            isOpen ? "block" : "hidden"
          )}
        >
          <CommandList className="rounded-lg ring-1 ring-slate-200" ref={scrollableRef}>
            {isLoading ? (
              <CommandLoading>
                <div className="p-1">
                  <Skeleton className="h-8 w-full" />
                </div>
              </CommandLoading>
            ) : (
              <>
                <CommandGroup>
                  {filteredOptions.map((option, index) => {
                    return (
                      <CommandItem
                        key={`${option.value}-${index}`}
                        value={option.value}
                        onMouseDown={event => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        onSelect={() => handleSelectOption()}
                        className="flex w-full items-center gap-2"
                      >
                        {option.display}
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
                <CommandEmpty className="select-none rounded-sm px-2 py-3 text-center text-sm">{emptyMessage}</CommandEmpty>
              </>
            )}
          </CommandList>
        </div>
      </div>
    </Command>
  );
};
