import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../config/backend";
import {
  AdminCreateInternalTrainingBody,
  AdminInternalTrainingRepository,
  AdminUpdateInternalTrainingBody,
} from "@shared-kernel/application/ports/admin/admin-internal-training-repository";
import { InternalTraining } from "@academy-context/read/domain/types/shared/internal-training";

export class AdminBackendInternalTrainingRepository extends HttpGateway implements AdminInternalTrainingRepository {
  private _route: string = "v1/admin/internal-trainings";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async list(): Promise<InternalTraining[]> {
    const { data: trainings } = await this._instance.get(this._route);
    return trainings;
  }

  async create(training: AdminCreateInternalTrainingBody): Promise<string> {
    return this._instance.put(this._route, training);
  }
  async update(training: AdminUpdateInternalTrainingBody): Promise<void> {
    await this._instance.post(`${this._route}/${training.trainingId}`, training);
  }
  async delete(trainingId: string): Promise<void> {
    await this._instance.delete(`${this._route}/${trainingId}`);
  }
}
