import { SyntheticEvent, useEffect, useState } from "react";
import { formatDateToLocale } from "../../../../utils/formatting";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { ADMIN_ACTION_URGENCY_LEVEL, AdminTrainingFollowUpListVM } from "@academy-context/read/domain/types/training-follow-up";
import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { useAppDispatch } from "src/redux/hooks";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { useTrainingFollowUpUpdated } from "@academy-context/primary/admin/training-follow-up-list/use-training-follow-up-updated";
import { toggleTrainingFollowUpDunningsStatus } from "@academy-context/write/application/use-cases/admin/training-follow-up-dunnings-status-toggle/toggle-training-follow-up-dunnings-status";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

const linkWrapper = (id: string, value: string) => (
  <Link to={`${ROLE_BASED_URLS[ROLES.ADMIN].trainingFollowUp.list}/${id}`}>
    <div className="size-full">
      <span>{value}</span>
    </div>
  </Link>
);

const formatAvailableActionUrgencyCell = (actionUrgency: ADMIN_ACTION_URGENCY_LEVEL) => {
  if (actionUrgency === ADMIN_ACTION_URGENCY_LEVEL.NONE) return null;
  let urgencyColor: "yellow" | "orange" | "red" = "yellow";
  if (actionUrgency === ADMIN_ACTION_URGENCY_LEVEL.MEDIUM) urgencyColor = "orange";
  else if (actionUrgency === ADMIN_ACTION_URGENCY_LEVEL.HIGH) urgencyColor = "red";
  return (
    <div className="flex justify-center">
      <DangerSign color={urgencyColor} />
    </div>
  );
};

const columns: ColumnDef<AdminTrainingFollowUpListVM>[] = [
  {
    accessorKey: "date",
    header: () => "Date de création",
    cell: ({ row }) => linkWrapper(row.original.id, formatDateToLocale(row.original.date)),
    meta: {
      title: "Date de création",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "internshipEndDate",
    header: () => "Fin de stage",
    cell: ({ row }) => linkWrapper(row.original.id, formatDateToLocale(row.original.internshipEndDate)),
    meta: {
      title: "Fin de stage",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "educationalAdvisor",
    header: () => "Professeur",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.educationalAdvisor),
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "availableActionUrgency.convention",
    header: () => "Conv.",
    cell: ({ row }) => {
      const {
        availableActionUrgency: { convention },
      } = row.original;
      return formatAvailableActionUrgencyCell(convention);
    },
    enableSorting: true,
    meta: {
      title: "Conv.",
      width: "60px",
    },
  },
  {
    accessorKey: "availableActionUrgency.teacherSession",
    header: () => "Emarg. P",
    cell: ({ row }) => {
      const {
        availableActionUrgency: { teacherSession },
      } = row.original;
      return formatAvailableActionUrgencyCell(teacherSession);
    },
    enableSorting: true,
    meta: {
      title: "Emarg. P",
      width: "65px",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.student),
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "availableActionUrgency.studentSession",
    header: () => "Emarg. E",
    cell: ({ row }) => {
      const {
        availableActionUrgency: { studentSession },
      } = row.original;
      return formatAvailableActionUrgencyCell(studentSession);
    },
    enableSorting: true,
    meta: {
      title: "Emarg. E",
      width: "65px",
    },
  },
  {
    accessorKey: "availableActionUrgency.surveys.preTraining",
    header: () => "Quest. Pré",
    cell: ({ row }) => {
      const {
        availableActionUrgency: {
          surveys: { preTraining },
        },
      } = row.original;
      return formatAvailableActionUrgencyCell(preTraining);
    },
    enableSorting: true,
    meta: {
      title: "Quest. Pré",
      width: "65px",
    },
  },
  {
    accessorKey: "availableActionUrgency.surveys.postTraining",
    header: () => "Quest. Post",
    cell: ({ row }) => {
      const {
        availableActionUrgency: {
          surveys: { postTraining },
        },
      } = row.original;
      return formatAvailableActionUrgencyCell(postTraining);
    },
    enableSorting: true,
    meta: {
      title: "Quest. Post",
      width: "65px",
    },
  },
  {
    accessorKey: "availableActionUrgency.studentObjectives",
    header: () => "Suivi pédago.",
    cell: ({ row }) => {
      const {
        availableActionUrgency: { studentObjectives },
      } = row.original;
      return formatAvailableActionUrgencyCell(studentObjectives);
    },
    enableSorting: true,
    meta: {
      title: "Suivi pédago.",
      width: "70px",
    },
  },
];

interface Props {
  trainings: AdminTrainingFollowUpListVM[];
}

export const TrainingFollowUpTable = ({ trainings }: Props) => {
  const dispatch = useAppDispatch();
  const [dataColumns, setDataColumns] = useState<ColumnDef<AdminTrainingFollowUpListVM>[]>([]);

  useEffect(() => {
    const statusColumn: ColumnDef<AdminTrainingFollowUpListVM> = {
      accessorKey: "isDunningProcessPaused",
      header: () => "Stop Relance",
      meta: {
        title: "Stop Relance",
        width: "65px",
      },
      enableSorting: false,
      cell: info => {
        const { row } = info;
        const training = row.original;
        const isDunningProcessPaused = info.row.original.isDunningProcessPaused;
        const action = isDunningProcessPaused ? "resume" : "pause";
        return (
          <Checkbox
            id={training.id}
            defaultChecked={isDunningProcessPaused}
            onClick={(event: SyntheticEvent) => {
              // Prevents checkbox from being checked/unchecked
              event.preventDefault();
              dispatch(toggleTrainingFollowUpDunningsStatus({ id: training.id, action }));
            }}
          />
        );
      },
    };
    const [conventionDate, internshipEndDate, ...rest] = columns;

    setDataColumns([conventionDate!, internshipEndDate!, statusColumn, ...rest]);
  }, [dispatch, trainings]);

  useTrainingFollowUpUpdated();

  return (
    <CustomCard title="Suivi de formation">
      <DataTable
        columns={dataColumns}
        data={trainings}
        sortField="internshipEndDate"
        order="desc"
        searchPlaceHolder="Rechercher une formation"
        pageSize={50}
        displayDataViewOptions
      />
    </CustomCard>
  );
};
