import { useEffect } from "react";
import { retrieveTrainingFollowUpSessions } from "../../../read/application/use-cases/shared/training-follow-up-sessions-retrieval/retrieve-training-follow-up-sessions";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { teacherRetrieveTrainingFollowUp } from "../../../read/application/use-cases/teacher/training-follow-up-retrieval/retrieve-training-follow-up";
import { TeacherAttendanceSheetForm } from "@academy-context/primary/shared/attendance-sheet-signature-form/teacher-attendance-sheet-form";

interface Props {
  isAdmin?: boolean;
}

export const TeacherTrainingFollowUpAttendanceSheetListContainer = ({ isAdmin }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: trainingSessions } = useAppSelector((state: AppState) => state.trainingFollowUpSessionsRetrieval);
  const { data: trainingFollowUp } = useAppSelector((state: AppState) => state.teacherTrainingFollowUpRetrieval);

  useEffect(() => {
    if (id) dispatch(teacherRetrieveTrainingFollowUp(id));
    if (id) dispatch(retrieveTrainingFollowUpSessions(id));
  }, [dispatch, id]);

  return (
    <TeacherAttendanceSheetForm
      trainingFollowUpSessions={trainingSessions}
      trainingFollowUp={trainingFollowUp}
      trainingFollowUpId={id}
      isAdmin={isAdmin}
    />
  );
};
