import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveTrainingFollowUpObjectives } from "../../../read/application/use-cases/shared/training-follow-up-objectives-retrieval/retrieve-training-follow-up-objectives";
import { TrainingFollowUpObjectives } from "../../shared/training-follow-up-objective-form/training-follow-up-objectives.components";
import { useObjectiveUpdate } from "../../shared/training-follow-up/use-objective-update";

export const StudentTrainingFollowUpObjectives = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: objectives } = useAppSelector((state: AppState) => state.trainingFollowUpObjectivesRetrieval);

  useEffect(() => {
    if (id) dispatch(retrieveTrainingFollowUpObjectives({ trainingId: id, role: "student" }));
  }, [dispatch, id]);

  useObjectiveUpdate({ trainingId: id!, role: "student" });

  if (!id) return <></>;

  return <TrainingFollowUpObjectives objectives={objectives} role="student" trainingFollowUpId={id} />;
};
