import { Button } from "@components/ui/button";
import { FormControl, FormField, FormItem, FormMessage } from "@components/ui/form";
import { Input } from "@components/ui/input";
import { Label } from "@components/ui/label";
import { Separator } from "@components/ui/separator";
import { Nullable } from "@shared-kernel/core/types/nullable";
import RichText from "@shared-kernel/primary/shared/rich-text/rich-text";
import {
  ProviderTrainingFormInputs,
  formatProviderTrainingDataToFormData,
  providerTrainingdefaultValues,
} from "@academy-context/primary/shared/provider-trainings/form-validation/provider-training";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { useModal } from "@hooks/useModal";

interface TrainingFormProps {
  training: Nullable<Omit<ProviderTraining, "providerId">>;
  onDelete: (trainingId: string) => void;
  isNew: boolean;
}

export function ProviderTrainingForm({ training, onDelete, isNew }: TrainingFormProps) {
  const form = useFormContext<ProviderTrainingFormInputs>();
  const { closeModal, isModalOpen, openModal } = useModal();
  const {
    control,
    reset,
    formState: { errors, isSubmitted, isDirty },
    watch,
    resetField,
    setValue,
    register,
  } = form;
  const { fields } = useFieldArray({
    control,
    name: "questions",
  });

  const isAnUpdateForm = !isNew;

  useEffect(() => {
    if (training) reset(formatProviderTrainingDataToFormData(training));
    else reset(providerTrainingdefaultValues);
  }, [reset, training]);

  const handleOnDelete = () => {
    if (training) onDelete(training.id);
  };

  const objective = watch("objective");
  const handleObjectiveChange = (value: { html: string; text: string }) => {
    const isDirty = control._defaultValues.objective !== value.html;
    // We use resetField because it seems like shouldDirty doesn't work when setting it to false
    if (isDirty) {
      setValue("objective", value.html, { shouldDirty: isDirty, shouldValidate: isSubmitted });
      setValue("objectiveText", value.text.trim(), { shouldDirty: isDirty, shouldValidate: isSubmitted });
    } else {
      resetField("objective");
      resetField("objectiveText");
    }
  };

  const handleResetObjective = (value: string) => {
    resetField("objective", { defaultValue: value });
  };

  const description = watch("description");
  const handleDescriptionChange = (value: { html: string; text: string }) => {
    const isDirty = control._defaultValues.description !== value.html;
    // We use resetField because it seems like shouldDirty doesn't work when setting it to false
    if (isDirty) {
      setValue("description", value.html, { shouldDirty: isDirty, shouldValidate: isSubmitted });
      setValue("descriptionText", value.text.trim(), { shouldDirty: isDirty, shouldValidate: isSubmitted });
    } else {
      resetField("description");
      resetField("descriptionText");
    }
  };

  const handleResetDescription = (value: string) => {
    resetField("description", { defaultValue: value });
  };

  return (
    <>
      <AlertModal
        isOpen={isModalOpen}
        onClose={() => closeModal()}
        onSubmit={() => {
          closeModal();
          handleOnDelete();
        }}
        title="Supprimer cette formation"
        body={`Êtes-vous sûr de vouloir supprimer la formation : ${training?.title} ?`}
        submitText="Supprimer"
      />
      <div className="flex h-full flex-col">
        <div className="mt-2 grid gap-2">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="name">Titre</Label>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tag"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="name">Acronyme</Label>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormItem>
            <Label htmlFor="name">Objectif de la formation</Label>
            <FormControl>
              <RichText
                value={objective}
                onChange={handleObjectiveChange}
                onReset={handleResetObjective}
                hasError={Boolean((errors.objective && errors.objective.message) || (errors.objectiveText && errors.objectiveText.message))}
              />
            </FormControl>
          </FormItem>
          <FormItem>
            <Label htmlFor="name">Description de la formation</Label>
            <FormControl>
              <RichText
                value={description}
                onChange={handleDescriptionChange}
                onReset={handleResetDescription}
                hasError={Boolean(
                  (errors.description && errors.description.message) || (errors.descriptionText && errors.descriptionText.message)
                )}
              />
            </FormControl>
          </FormItem>
          <FormField
            control={form.control}
            name="team"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="name">Equipe pédagogique</Label>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="price"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="name">Tarif</Label>
                <FormControl>
                  <Input type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {fields.map((question, index) => (
            <FormField
              key={question.id}
              control={control}
              name={`questions.${index}.text`}
              render={() => (
                <FormItem>
                  <Label>Question {question.order}</Label>
                  <FormControl>
                    <Input type="text" {...register(`questions.${index}.text`)} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}
        </div>
        <Separator className="mt-4" />
        <div className="mt-4 flex justify-end gap-4">
          <Button onClick={() => openModal()} variant="destructive" disabled={!isAnUpdateForm} type="button">
            Supprimer
          </Button>
          <Button type="submit" disabled={!isDirty}>
            {isAnUpdateForm ? "Sauvegarder" : "Créer"}
          </Button>
        </div>
      </div>
    </>
  );
}
