import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { EducationalAdvisorTrainingFollowUpVM } from "../../../../domain/types/training-follow-up";

export const providerRetrieveTrainingFollowUp = createAsyncThunk<
  EducationalAdvisorTrainingFollowUpVM,
  string,
  { extra: Partial<Dependencies> }
>("trainingFollowUps/providerRetrieveTrainingFollowUp", async (id: string, { extra: { providerTrainingFollowUpRepository } }) => {
  return providerTrainingFollowUpRepository!.byId(id);
});
