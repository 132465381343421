import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { providerRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/provider/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { EducationalAdvisorTrainingFollowUpList } from "@academy-context/primary/shared/training-follow-up/training-follow-up-list.components";

export const ProviderTrainingFollowUpListContainer = () => {
  const dispatch = useAppDispatch();
  const { data: trainingFollowUps } = useAppSelector((state: AppState) => state.providerTrainingFollowUpsRetrieval);

  useEffect(() => {
    dispatch(providerRetrieveTrainingFollowUps());
  }, [dispatch]);

  return <EducationalAdvisorTrainingFollowUpList trainingFollowUps={trainingFollowUps} />;
};
