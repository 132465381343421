import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../config/backend";
import { TrainingFollowUpPort } from "../../application/ports/training-follow-up-port";
import {
  AdminTrainingFollowUpListVM,
  AdminTrainingFollowUpVM,
  StudentTrainingFollowUpVM,
  TrainingFollowUpListVM,
  EducationalAdvisorTrainingFollowUpVM,
  TrainingFollowUpObjective,
  TrainingFollowUpSession,
} from "@academy-context/read/domain/types/training-follow-up";
import { urlToBloB } from "../../../../utils/utils";
import { SignAttendanceSheetPayload } from "../../../write/application/use-cases/shared/attendance-sheet-signature/sign-attendance-sheet";
import { UpdateTrainingFollowUpObjectivePayload } from "../../../write/application/use-cases/shared/training-follow-up-objective-update/update-training-follow-up-objective";

export class BackendTrainingFollowUpGateway extends HttpGateway implements TrainingFollowUpPort {
  private _route: string = "v1/training-follow-ups";
  private _studentRoute: string = "v1/student/training-follow-ups";
  private _teacherRoute: string = "v1/teacher/training-follow-ups";
  private _adminRoute: string = "v1/admin/training-follow-ups";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async getTrainingFollowUpSessions(trainingId: string): Promise<TrainingFollowUpSession[]> {
    const { data: sessions } = await this._instance.get(`${this._route}/${trainingId}/sessions`);
    return sessions;
  }

  async getTrainingFollowUpObjectives(trainingId: string, role: "student" | "teacher"): Promise<TrainingFollowUpObjective[]> {
    const { data: objectives } = await this._instance.get(`${this._route}/${trainingId}/objectives?role=${role}`);
    return objectives;
  }

  async studentGetAll(): Promise<TrainingFollowUpListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._studentRoute}`);
    return trainings;
  }

  async teacherGetAll(): Promise<TrainingFollowUpListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._teacherRoute}`);
    return trainings;
  }

  async adminGetAll(): Promise<AdminTrainingFollowUpListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._adminRoute}`);
    return trainings;
  }

  async studentGetOne(id: string): Promise<StudentTrainingFollowUpVM> {
    const { data: training } = await this._instance.get(`${this._studentRoute}/${id}`);
    return training;
  }

  async teacherGetOne(id: string): Promise<EducationalAdvisorTrainingFollowUpVM> {
    const { data: training } = await this._instance.get(`${this._teacherRoute}/${id}`);
    return training;
  }

  async adminGetOne(id: string): Promise<AdminTrainingFollowUpVM> {
    const { data: training } = await this._instance.get(`${this._adminRoute}/${id}`);
    return training;
  }

  async signAttendanceSheet({ trainingId, id, role, signatureUrl }: SignAttendanceSheetPayload): Promise<void> {
    const blob = await urlToBloB(signatureUrl);
    const formDataBody = new FormData();
    formDataBody.append("role", role);
    formDataBody.append("signature", blob);

    const res = await this._instance.put(`${this._route}/${trainingId}/sessions/${id}:sign`, formDataBody);
    return res.data;
  }

  async updateTrainingFollowUpObjective({ trainingId, id, done, role }: UpdateTrainingFollowUpObjectivePayload): Promise<void> {
    await this._instance.put(`${this._route}/${trainingId}/objectives/${id}`, { done, role });
  }

  async toggleTrainingFollowUpDunningProcessStatus({
    trainingId,
    action,
  }: {
    trainingId: string;
    action: "pause" | "resume";
  }): Promise<void> {
    await this._instance.put(`${this._adminRoute}/${trainingId}:toggle-dunning-status`, { action });
  }

  async uploadSignedConvention(trainingId: string, file: File): Promise<string> {
    const formDataBody = new FormData();
    formDataBody.append("convention", file);

    const res = await this._instance.post(`${this._route}/${trainingId}/conventions:sign`, formDataBody);
    return res.data;
  }

  async deleteSignedConvention(trainingId: string): Promise<void> {
    await this._instance.post(`${this._route}/${trainingId}/conventions:delete`);
  }
}
