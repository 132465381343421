import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetUpdateProviderTraining } from "@academy-context/write/application/use-cases/provider/provider-training-update/update-provider-training";
import { retrieveProviderTrainings } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/retrieve-provider-trainings";

export const selectProviderTrainingUpdated = (state: AppState) => state.providerTrainingUpdate;

export const useProviderTrainingUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedId, processing } = useAppSelector(selectProviderTrainingUpdated);

  useEffect(() => {
    if (processing === "success" && justUpdatedId) {
      toast({ description: "Formation mise à jour !" });
      dispatch(resetUpdateProviderTraining());
      dispatch(retrieveProviderTrainings());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la mise à jour de la formation", variant: "destructive" });
    }
  }, [dispatch, justUpdatedId, processing, toast]);
};
