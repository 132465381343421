import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useNavigate } from "react-router-dom";
import { adminRetrieveLessons } from "../../../read/application/use-cases/admin/lessons-retrieval/all/retrieve-lessons";
import { extractCSV } from "../../../../utils/download";
import { formatDateToLocale, formatNumberToLocale, formatToCurrency } from "../../../../utils/formatting";
import { Modal } from "@shared-kernel/primary/shared/modal/modal";
import { format, endOfDay, startOfDay } from "date-fns";
import { getLocationPrice, getPlaceName } from "@academy-context/write/domain/services/locations";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { AppState } from "@redux/app-state";
import { AdminLessonListVM } from "@academy-context/read/domain/types/admin/lesson";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { cn } from "@components/utils/utils";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

const underConventionFormatter = (s: string | number, isUnderConvention: boolean) => (
  <span className={cn(isUnderConvention && "font-bold text-theme")}>{s}</span>
);

const columns: ColumnDef<AdminLessonListVM>[] = [
  {
    accessorKey: "isUnderConvention",
    header: () => {},
    cell: () => {},
    enableSorting: false,
    meta: {
      getCellContext: (context: CellContext<AdminLessonListVM, unknown>) => {
        const isUnderConvention = context.getValue();
        return {
          className: isUnderConvention ? "bg-theme" : "",
        };
      },
      title: "Sous convention",
      width: "20px",
    },
  },
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => {
      const { isUnderConvention, creationDate } = row.original;
      const localeDate = formatDateToLocale(creationDate);
      return underConventionFormatter(localeDate, isUnderConvention);
    },
    meta: {
      title: "Enregistré le",
      width: "100px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "date",
    header: () => "Date du cours",
    cell: ({ row }) => {
      const { isUnderConvention, date } = row.original;
      const localeDate = formatDateToLocale(date);
      return underConventionFormatter(localeDate, isUnderConvention);
    },
    meta: {
      title: "Date du cours",
      width: "100px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "studentName",
    header: () => "Elève",
    cell: ({ row }) => {
      const { isUnderConvention, studentName, studentLastName } = row.original;
      return underConventionFormatter(`${studentName} ${studentLastName}`, isUnderConvention);
    },
    enableSorting: true,
    meta: {
      title: "Elève",
    },
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    cell: ({ row }) => {
      const { isUnderConvention, teacher } = row.original;
      return underConventionFormatter(teacher, isUnderConvention);
    },
    enableSorting: true,
    meta: {
      title: "Professeur",
    },
  },
  {
    accessorKey: "hours",
    header: () => "Nbr. heures",
    cell: ({ row }) => {
      const { isUnderConvention, hours } = row.original;
      return underConventionFormatter(hours, isUnderConvention);
    },
    meta: {
      title: "Nbr. heures",
      width: "65px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire",
    cell: ({ row }) => {
      const { isUnderConvention, hourlyPrice } = row.original;
      return underConventionFormatter(formatToCurrency(hourlyPrice), isUnderConvention);
    },
    meta: {
      title: "Tarif horaire",
      width: "65px",
    },
  },
  {
    accessorKey: "place",
    header: () => "Studio",
    cell: ({ row }) => {
      const { isUnderConvention, place } = row.original;
      return underConventionFormatter(getPlaceName(place.paidByTeacher, place.name), isUnderConvention);
    },
    meta: {
      title: "Studio",
    },
  },
  {
    accessorKey: "place.price",
    header: () => "Tarif horaire studio",
    cell: ({ row }) => {
      const { isUnderConvention, place } = row.original;
      return underConventionFormatter(formatToCurrency(place.price ?? 0), isUnderConvention);
    },
    meta: {
      title: "Tarif horaire studio",
      width: "65px",
    },
  },
  {
    accessorKey: "amountChargedOnStudentBalance",
    header: () => "Montant imputé sur le solde de l'élève",
    cell: ({ row }) => {
      const { isUnderConvention, amountChargedOnStudentBalance } = row.original;
      return underConventionFormatter(formatToCurrency(amountChargedOnStudentBalance), isUnderConvention);
    },
    meta: {
      title: "Montant imputé sur le solde de l'élève",
      width: "65px",
    },
  },
  {
    accessorKey: "paidToTeacher",
    header: () => "Règlement professeur",
    cell: ({ row }) => {
      const { isUnderConvention, paidToTeacher } = row.original;
      return underConventionFormatter(formatToCurrency(paidToTeacher), isUnderConvention);
    },
    meta: {
      title: "Règlement professeur",
      width: "80px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    meta: {
      title: "Commentaire",
    },
  },
];

const isValidInterval = (from: Date | null, to: Date | null): boolean => {
  if (!(from && to)) return false;
  return from <= to;
};

export const LessonsList = () => {
  const now = new Date();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: lessons } = useAppSelector((state: AppState) => state.adminLessonsRetrieval);
  const [from, setFrom] = useState<Date>(now);
  const [to, setTo] = useState<Date>(now);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(adminRetrieveLessons());
  }, [dispatch]);

  const onNewLesson = () => {
    navigate(ROLE_BASED_URLS[ROLES.ADMIN].lesson.base);
  };

  const onExport = () => {
    const start = startOfDay(from!);
    const end = endOfDay(to!);
    const filteredLessons = lessons.filter(l => new Date(l.creationDate) >= start! && new Date(l.creationDate) <= end!);
    const dataToExport = filteredLessons
      .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())
      .map(l => {
        const placeName = getPlaceName(l.place.paidByTeacher, l.place.name);
        const isPersonalTeacherStudio = l.place.price === null;
        const placePrice = !isPersonalTeacherStudio
          ? formatNumberToLocale(l.place.price!)
          : l.place.name
          ? getLocationPrice(l.place.name)
          : null;
        return [
          formatDateToLocale(l.creationDate),
          l.id,
          l.studentLastName,
          l.studentName,
          l.teacher,
          formatDateToLocale(l.date),
          formatNumberToLocale(l.hours, 2),
          formatNumberToLocale(l.hourlyPrice, 2),
          placeName,
          placePrice,
          formatNumberToLocale(l.amountChargedOnStudentBalance, 2),
          formatNumberToLocale(l.paidToTeacher, 2),
          l.commentary,
          l.isUnderConvention,
          l.isUnderOldConvention,
          l.isUnderConvention && !l.isUnderOldConvention,
        ];
      });
    const fields = [
      "enregistré le",
      "identifiant",
      "nom",
      "prenom",
      "professeur",
      "date du cours",
      "heures",
      "taux horaire",
      "studio",
      "taux horaire studio",
      "total imputé à l'élève",
      "règlement professeur",
      "commentaire",
      "sous_convention",
      "sous_ancienne_convention",
      "sous_nouvelle_convention",
    ];

    const fileName = `lessons_extract_${format(from!, "yyyy-MM-dd")}_${format(to!, "yyyy-MM-dd")}.csv`;

    extractCSV(dataToExport, fields, fileName);
  };

  const onModalSubmit = () => {
    onExport();
    setFrom(now);
    setTo(now);
    setIsModalOpen(false);
  };

  const modalBody = (
    <>
      <div className="grid grid-cols-4 items-center">
        <Label className="col-span-1">A partir de:</Label>
        <div className="col-span-3">
          <PopoverCalendar value={from.toISOString()} onChange={value => setFrom(value)} />
        </div>
      </div>
      <div className="grid grid-cols-4 items-center">
        <Label className="col-span-1">Jusqu'au:</Label>
        <div className="col-span-3">
          <PopoverCalendar value={to.toISOString()} onChange={value => setTo(value)} />
        </div>
      </div>
    </>
  );

  return (
    <>
      <CustomCard
        title="Cours"
        headerChildren={
          <div>
            <Button onClick={() => setIsModalOpen(true)} color="light">
              Télécharger les cours
            </Button>
            <Button className="ml-4" onClick={onNewLesson} color="light">
              Nouveau cours
            </Button>
          </div>
        }
      >
        <DataTable
          columns={columns}
          data={lessons}
          sortField="creationDate"
          order="desc"
          searchPlaceHolder="Rechercher un cours"
          displayDataViewOptions
          pageSize={50}
        />
      </CustomCard>
      <Modal
        title="Télécharger les cours"
        body={modalBody}
        onSubmit={onModalSubmit}
        onClose={() => setIsModalOpen(false)}
        submitText="Télécharger"
        submitDisabled={!isValidInterval(from, to)}
        isOpen={isModalOpen}
      />
    </>
  );
};
