import { ACTION_URGENCY_LEVEL } from "@academy-context/read/domain/types/training-follow-up";
import { Link } from "react-router-dom";
import { TrainingFollowUpElement } from "./element";

interface Props {
  title: string;
  status: ACTION_URGENCY_LEVEL;
  subTitle?: string;
  link?: string;
}

export const LinkTrainingFollowUpElement = ({ title, status, subTitle, link }: Props) => {
  const content = <TrainingFollowUpElement title={title} status={status} subTitle={subTitle} />;

  if (!link) return content;
  return <Link to={link}>{content}</Link>;
};
