import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminTrainingFollowUpVM } from "../../../../domain/types/training-follow-up";

export const adminRetrieveTrainingFollowUp = createAsyncThunk<AdminTrainingFollowUpVM, string, { extra: Partial<Dependencies> }>(
  "trainingFollowUps/adminRetrieveTrainingFollowUp",
  async (id: string, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.adminGetOne(id);
  }
);
