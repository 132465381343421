import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogDescription, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { UpdateTimelineEventBody } from "@user-management-context/shared/application/ports/admin-student-gateway";
import {
  defaultTimelineEventValues,
  TimelineEventFormInputs,
  timelineEventSchema,
  formatFormDataToUpdateBodyData,
  formatDataToFormData,
} from "@academy-context/primary/admin/student-detail/form-validation/timeline-event";
import { Student } from "@user-management-context/read/domain/types/admin/student";
import { StudentTimelineEvent } from "@academy-context/read/domain/types/admin/student-timeline";
import { TimelineEventForm } from "@academy-context/primary/admin/student-detail/timeline-event-form";
import { ScrollableDialogContent } from "@components/ui/scrollable-dialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (body: UpdateTimelineEventBody) => void;
  student: Student;
  event: StudentTimelineEvent;
}

export const UpdateTimelineEventModal = ({ isOpen, onClose, onSubmit, student, event }: Props) => {
  const form = useForm<TimelineEventFormInputs>({
    resolver: yupResolver(timelineEventSchema),
    defaultValues: defaultTimelineEventValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  const handleOnSubmit = (formBody: TimelineEventFormInputs) => {
    const body = formatFormDataToUpdateBodyData({ studentId: student.id, timelineEventId: event.id }, formBody);
    onSubmit(body);
    onClose();
  };

  useEffect(() => {
    reset(formatDataToFormData(event));
  }, [reset, event]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose} modal>
      <ScrollableDialogContent onPointerDownOutside={onClose} className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Mettre à jour un évènement</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)} className="space-y-4">
            <TimelineEventForm isDirty={isDirty} onClose={onClose} />
          </form>
        </FormProvider>
      </ScrollableDialogContent>
    </Dialog>
  );
};
