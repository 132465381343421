import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveProviderTrainings } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/retrieve-provider-trainings";
import { adminResetDeleteProviderTraining } from "@academy-context/write/application/use-cases/admin/provider-training-deletion/delete-provider-training";

export const selectProviderTrainingDeleted = (state: AppState) => state.adminProviderTrainingDeletion;

interface Props {
  providerId: string;
}

export const useProviderTrainingDeleted = ({ providerId }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectProviderTrainingDeleted);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Formation supprimée !" });
      dispatch(adminResetDeleteProviderTraining());
      dispatch(adminRetrieveProviderTrainings(providerId));
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la suppression de la formation", variant: "destructive" });
    }
    // We don't want to react on providerId change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, processing, toast]);
};
