import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectConventionsRetrievalForList } from "../../../read/application/use-cases/admin/conventions-retrieval/selectors/conventions-list-selectors";
import { useNavigate } from "react-router-dom";
import { retrieveConventions } from "../../../read/application/use-cases/admin/conventions-retrieval/retrieve-conventions";
import { AdminConventionListVM } from "@academy-context/read/domain/types/admin/convention";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { useConventionCancellation } from "./use-convention-cancelled";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@shared-kernel/primary/shared/shadcn/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { useConventionEnding } from "./use-convention-ended";
import { computeConventionStatus } from "@academy-context/read/domain/services/convention";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { cancelConvention } from "@academy-context/write/application/use-cases/admin/convention-cancellation/cancel-convention";
import { endConvention } from "@academy-context/write/application/use-cases/admin/convention-ending/end-convention";
import { useModal } from "@hooks/useModal";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

const columns: ColumnDef<AdminConventionListVM>[] = [
  {
    accessorKey: "prematureEndDate",
    header: () => "Statut",
    meta: {
      title: "Statut",
      width: "80px",
    },
    cell: ({ row }) => {
      const { prematureEndDate, hoursLeft } = row.original;
      return computeConventionStatus(prematureEndDate, hoursLeft);
    },
  },
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: info => formatDateToLocale(info.row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
  },
  {
    accessorKey: "date",
    header: () => "Date",
    cell: info => formatDateToLocale(info.row.original.date),
    meta: {
      width: "90px",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "hours",
    header: () => "Heures",
    meta: {
      title: "Heures",
      width: "65px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire",
    cell: info => formatToCurrency(info.row.original.hourlyPrice),
    meta: {
      title: "Tarif horaire",
      width: "65px",
    },
  },
  {
    accessorKey: "hoursLeft",
    header: () => "Heures restantes",
    meta: {
      title: "Heures restantes",
      width: "80px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    meta: {
      title: "Commentaire",
    },
  },
];

export const ConventionsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: conventions } = useAppSelector(selectConventionsRetrievalForList);
  const [dataColumns, setDataColumns] = useState<ColumnDef<AdminConventionListVM>[]>([...columns]);
  const currentSelectedLineInfo = useRef<Nullable<AdminConventionListVM>>(null);
  const { closeModal: closeCancelModal, isModalOpen: isCancelModalOpen, openModal: openCancelModal } = useModal();
  const { closeModal: closeEndedModal, isModalOpen: isEndedModalOpen, openModal: openEndedModal } = useModal();

  useEffect(() => {
    dispatch(retrieveConventions());
  }, [dispatch]);

  const onNewConvention = () => {
    navigate(ROLE_BASED_URLS[ROLES.ADMIN].convention.base);
  };

  useEffect(() => {
    const cancelColumn: ColumnDef<AdminConventionListVM> = {
      accessorKey: "id",
      header: () => "Actions",
      enableSorting: false,
      meta: {
        title: "Actions",
        width: "60px",
      },
      cell: ({ row }) => {
        const isRunningConvention = row.original.hoursLeft > 0;
        const hasBeenEnded = Boolean(row.original.prematureEndDate);
        if (!isRunningConvention || hasBeenEnded) {
          return <></>;
        } else
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="size-8 p-0">
                  <span className="sr-only">Ouvrir le menu</span>
                  <DotsVerticalIcon className="size-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() => {
                    openCancelModal();
                    currentSelectedLineInfo.current = row.original;
                  }}
                >
                  Supprimer convention
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => {
                    openEndedModal();
                    currentSelectedLineInfo.current = row.original;
                  }}
                >
                  Terminer prématurément
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
      },
    };
    const [statusColumn, ...rest] = columns;
    setDataColumns([...rest, cancelColumn, statusColumn!]);
  }, [conventions, openCancelModal, openEndedModal]);

  useConventionCancellation();
  useConventionEnding();

  return (
    <CustomCard title="Convention" headerChildren={<Button onClick={onNewConvention}>Nouvelle convention</Button>}>
      {currentSelectedLineInfo.current && (
        <>
          <AlertModal
            title="Supprimer la convention"
            body={
              <div className="grid gap-4 py-4">
                <p>Êtes-vous sûr de vouloir supprimer la convention?</p>
                <p>Ceci entrainera la suppression de la convention ainsi que la clotûre du suivi de formation.</p>
              </div>
            }
            submitText="Supprimer"
            onSubmit={() => {
              closeCancelModal();
              if (currentSelectedLineInfo.current) {
                dispatch(cancelConvention(currentSelectedLineInfo.current.id));
              }
            }}
            isOpen={isCancelModalOpen}
            onClose={() => closeCancelModal()}
          />
          <AlertModal
            title="Terminer prématurément la convention"
            body={
              <div className="grid gap-4 py-4">
                <p>Êtes-vous sûr de vouloir terminer la convention?</p>
                <p>Ceci entrainera la fin de la convention. Le suivi de la formation sera toujours actif.</p>
              </div>
            }
            submitText="Terminer"
            onSubmit={() => {
              closeEndedModal();
              if (currentSelectedLineInfo.current) {
                dispatch(endConvention(currentSelectedLineInfo.current.id));
              }
            }}
            isOpen={isEndedModalOpen}
            onClose={() => closeEndedModal()}
          />
        </>
      )}
      <DataTable
        columns={dataColumns}
        data={conventions}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher une convention"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
