import config from "../../../../../config/backend";
import {
  AddTimelineEventBody,
  AdminStudentGateway,
  UpdateTimelineEventBody,
} from "@user-management-context/shared/application/ports/admin-student-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { AdminCreateStudentBody, AdminUpdateStudentBody } from "@user-management-context/write/domain/types/admin/student";
import { Student, StudentListElement } from "@user-management-context/read/domain/types/admin/student";
import { StudentSummaryVM } from "../../../../read/domain/types/student/student";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { StudentTimelineEvent } from "@academy-context/read/domain/types/admin/student-timeline";

export class AdminBackendStudentGateway extends HttpGateway implements AdminStudentGateway {
  private _route: string = "v1/admin/students";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async one(id: string): Promise<Student> {
    const { data: student } = await this._instance.get(`${this._route}/${id}`);
    return student;
  }

  async all(): Promise<StudentListElement[]> {
    const { data: students } = await this._instance.get(this._route);
    return students;
  }

  async create(student: AdminCreateStudentBody): Promise<string> {
    const { data: studentId } = await this._instance.put(this._route, student);
    return studentId;
  }

  async update(id: string, body: AdminUpdateStudentBody): Promise<void> {
    await this._instance.post(`${this._route}/${id}`, body);
  }

  async summary(id: string): Promise<StudentSummaryVM> {
    const { data: summary } = await this._instance.get(`${this._route}/${id}/summary`);
    return summary;
  }

  async timelineEvents(studentId: string): Promise<StudentTimelineEvent[]> {
    const { data: timelineEvents } = await this._instance.get(`${this._route}/${studentId}/timeline-events`);
    return timelineEvents;
  }

  async addStudentTimelineEvent(body: AddTimelineEventBody): Promise<void> {
    return this._instance.put(`${this._route}/${body.studentId}/timeline-events`, body);
  }
  async updateStudentTimelineEvent(body: UpdateTimelineEventBody): Promise<void> {
    return this._instance.post(`${this._route}/${body.studentId}/timeline-events/${body.timelineEventId}`, body);
  }
}
