import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "@components/ui/sidebar";
import { Link } from "react-router-dom";
import { User } from "@user-management-context/read/domain/types/user";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import logo from "../../../../assets/img/logo/logo.png";
import { ROLE_BASED_URLS } from "src/routes";

interface Props {
  user: Nullable<User>;
}

export function NavHeader({ user }: Props) {
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <SidebarMenuButton size="lg" asChild>
          <Link to={user && user.role !== ROLES.ADMIN ? ROLE_BASED_URLS[user.role].dashboard : ROLE_BASED_URLS[ROLES.ADMIN].payment.list}>
            <img src={logo} alt="logo" />
          </Link>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
