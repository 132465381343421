import { useEffect } from "react";
import { retrieveTrainingFollowUpSessions } from "../../../read/application/use-cases/shared/training-follow-up-sessions-retrieval/retrieve-training-follow-up-sessions";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { AttendanceSheetForm } from "./attendance-sheet-form";
import { studentRetrieveTrainingFollowUp } from "../../../read/application/use-cases/student/training-follow-up-retrieval/retrieve-training-follow-up";

interface Props {
  isAdmin?: boolean;
}

export const StudentAttendanceSheetForm = ({ isAdmin = false }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: trainingSessions } = useAppSelector((state: AppState) => state.trainingFollowUpSessionsRetrieval);
  const { data: training } = useAppSelector((state: AppState) => state.studentTrainingFollowUpRetrieval);

  useEffect(() => {
    if (id) dispatch(studentRetrieveTrainingFollowUp(id));
    if (id) dispatch(retrieveTrainingFollowUpSessions(id));
  }, [dispatch, id]);

  if (trainingSessions.length === 0 || !training || !id) return <></>;

  const formattedAttendanceSheets = trainingSessions.map(s => ({
    id: s.id,
    scheduledDate: new Date(s.scheduledDate),
    signatureUrl: s.studentSignatureUrl,
    number: s.number,
  }));

  return (
    <AttendanceSheetForm
      role="student"
      title={`${training.title} | ${training.educationalAdvisor} | ${training.externalFundingRequestId}`}
      conventionDate={new Date(training.date)}
      attendanceSheets={formattedAttendanceSheets}
      trainingFollowUpId={id}
      isAdmin={isAdmin}
    />
  );
};
