import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const uploadSignedConvention = createAsyncThunk<string, { id: string; convention: File }, { extra: Partial<Dependencies> }>(
  "trainingFollowUps/uploadSignedConvention",
  async ({ id, convention }: { id: string; convention: File }, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.uploadSignedConvention(id, convention);
  }
);

export const resetUploadSignedConvention = createAction("trainingFollowUps/resetUploadSignedConvention");
