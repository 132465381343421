import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export interface UpdateTrainingFollowUpObjectivePayload {
  trainingId: string;
  id: string;
  role: "student" | "teacher";
  done: string;
}

export const updateTrainingFollowUpObjective = createAsyncThunk<
  void,
  UpdateTrainingFollowUpObjectivePayload,
  { extra: Partial<Dependencies> }
>(
  "trainingSessions/updateTrainingFollowUpObjective",
  async (payload: UpdateTrainingFollowUpObjectivePayload, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.updateTrainingFollowUpObjective(payload);
  }
);
