import { Dependencies } from "@redux/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AdminUpdateInternalTrainingBody } from "@shared-kernel/application/ports/admin/admin-internal-training-repository";

export const adminUpdateInternalTraining = createAsyncThunk<void, AdminUpdateInternalTrainingBody, { extra: Partial<Dependencies> }>(
  "internalTrainings/updateInternalTraining",
  async (body: AdminUpdateInternalTrainingBody, { extra: { adminInternalTrainingRepository } }) => {
    return adminInternalTrainingRepository!.update(body);
  }
);

export const adminResetUpdateInternalTraining = createAction("internalTrainings/resetUpdateInternalTraining");
