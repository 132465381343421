import { Link } from "react-router-dom";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

const linkWrapper = (id: string, value: Nullable<string>) => {
  return (
    <Link to={`${ROLE_BASED_URLS[ROLES.ADMIN].provider.list}/${id}`}>
      <div className="size-full">
        <span>{value}</span>
      </div>
    </Link>
  );
};

const columns: ColumnDef<ProviderListItem>[] = [
  {
    accessorKey: "tag",
    header: () => "Tag",
    cell: info => linkWrapper(info.row.original.id, info.row.original.tag),
    enableSorting: true,
    meta: {
      title: "Tag",
      width: "60px",
    },
  },
  {
    accessorKey: "name",
    header: () => "Raison sociale",
    cell: info => linkWrapper(info.row.original.id, info.row.original.name),
    enableSorting: true,
    meta: {
      title: "Raison sociale",
      width: "300px",
    },
  },
];

interface Props {
  providers: ProviderListItem[];
}

export const ProviderListComponent = ({ providers }: Props) => {
  return (
    <DataTable columns={columns} data={providers} sortField="tag" order="asc" searchPlaceHolder="Rechercher un prestataire" pageSize={50} />
  );
};
