import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { useStudentUpdate } from "@academy-context/primary/admin/student-detail/use-student-update";
import { selectStudentRetrieval } from "@user-management-context/read/application/use-cases/admin/student-retrieval/selectors/student-detail-selectors";
import { selectStudentSummaryRetrieval } from "@academy-context/read/application/use-cases/student/summary-retrieval/selectors/student-summary-selectors";
import { retrieveStudent } from "@user-management-context/read/application/use-cases/admin/student-retrieval/retrieve-student";
import { retrieveStudentSummary } from "@academy-context/read/application/use-cases/student/summary-retrieval/retrieve-student-summary";
import { adminRetrieveTeachers } from "@user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { updateStudent } from "@user-management-context/write/application/use-cases/admin/student-update/update-student";
import { AdminCreateStudentBody, AdminUpdateStudentBody } from "@user-management-context/write/domain/types/admin/student";
import { StudentSummary } from "@academy-context/primary/student/student-summary/student-summary.components";
import { StudentForm } from "@academy-context/primary/admin/student-detail/student-form.component";
import { StudentDetailsSkeleton } from "@academy-context/primary/admin/student-detail/student-details.skeleton";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { StudentTimeline } from "@academy-context/primary/admin/student-detail/student-timeline.components";
import { retrieveStudentTimelineEvents } from "@academy-context/read/application/use-cases/admin/student-timeline-events-retrieval/retrieve-student-timeline-events";
import { AppState } from "@redux/app-state";
import { AddTimelineEventBody, UpdateTimelineEventBody } from "@user-management-context/shared/application/ports/admin-student-gateway";
import { addStudentTimelineEvent } from "@academy-context/write/application/use-cases/admin/student-timeline-event-addition/add-student-timeline-event";
import { updateStudentTimelineEvent } from "@academy-context/write/application/use-cases/admin/student-timeline-event-update/update-student-timeline-event";
import { useStudentTimelineEventAdditionOrUpdate } from "@academy-context/primary/admin/student-detail/use-student-timeline-event-addition-or-update";

export const StudentDetailsContainer = () => {
  const dispatch = useAppDispatch();
  const { student, isLoading } = useAppSelector(selectStudentRetrieval);
  const { data } = useAppSelector(selectStudentSummaryRetrieval);
  const { data: timelineEvents } = useAppSelector((appState: AppState) => appState.studentTimelineEventsRetrieval);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(retrieveStudent(id));
      dispatch(retrieveStudentSummary(id));
      dispatch(retrieveStudentTimelineEvents(id));
    }
    dispatch(adminRetrieveTeachers());
  }, [id, dispatch]);

  const saveStudent = async (student: AdminCreateStudentBody | AdminUpdateStudentBody) => {
    await dispatch(updateStudent({ id: id as string, body: student as AdminUpdateStudentBody }));
  };

  const addTimelineEvent = (body: AddTimelineEventBody) => {
    dispatch(addStudentTimelineEvent(body));
  };

  const onUpdateEvent = (body: UpdateTimelineEventBody) => {
    dispatch(updateStudentTimelineEvent(body));
  };

  useStudentUpdate();
  useStudentTimelineEventAdditionOrUpdate(id);

  return (
    <>
      <BackButton link={ROLE_BASED_URLS[ROLES.ADMIN].student.list} label="Retour à la liste" />
      <div className="space-y-4">
        <CustomCard title="Informations">
          {isLoading ? <StudentDetailsSkeleton /> : <StudentForm student={student} onSubmit={saveStudent} />}
        </CustomCard>
        {data && <StudentSummary summary={data} lastActiveConvention={data.lastActiveConvention} adminView={true} />}
        <CustomCard title="Timeline">
          {isLoading ? (
            <StudentDetailsSkeleton />
          ) : (
            <StudentTimeline
              timelineEvents={timelineEvents}
              onAddEvent={addTimelineEvent}
              onUpdateEvent={onUpdateEvent}
              student={student}
            />
          )}
        </CustomCard>
      </div>
    </>
  );
};
