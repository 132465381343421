import { Dependencies } from "@redux/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

export const adminDeleteInternalTraining = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "internalTrainings/deleteInternalTraining",
  async (trainingId: string, { extra: { adminInternalTrainingRepository } }) => {
    return adminInternalTrainingRepository!.delete(trainingId);
  }
);

export const adminResetDeleteInternalTraining = createAction("internalTrainings/resetDeleteInternalTraining");
