import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminTrainingFollowUpListVM } from "../../../../domain/types/training-follow-up";

export const adminRetrieveTrainingFollowUps = createAsyncThunk<AdminTrainingFollowUpListVM[], void, { extra: Partial<Dependencies> }>(
  "trainingFollowUps/adminRetrieveTrainingFollowUps",
  async (_: void, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.adminGetAll();
  }
);
