import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveInternalTrainings } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/retrieve-internal-trainings";
import { adminResetUpdateInternalTraining } from "@academy-context/write/application/use-cases/admin/internal-training-update/update-internal-training";

export const selectInternalTrainingUpdated = (state: AppState) => state.adminInternalTrainingUpdate;

export const useInternalTrainingUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedId, processing } = useAppSelector(selectInternalTrainingUpdated);

  useEffect(() => {
    if (processing === "success" && justUpdatedId) {
      toast({ description: "Formation mise à jour !" });
      dispatch(adminResetUpdateInternalTraining());
      dispatch(adminRetrieveInternalTrainings());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la mise à jour de la formation", variant: "destructive" });
    }
  }, [dispatch, justUpdatedId, processing, toast]);
};
